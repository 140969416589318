<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/move/list' }">运转列表</el-breadcrumb-item>
      <el-breadcrumb-item>异常管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="checkWrap">
      <el-form class="formBox" label-width="100px" v-for="item in form" :key="item.id" v-show="item.id==2">
        <div class="checkTitle">{{item.title}}</div>
        <el-form-item label="存在异常" style="margin-bottom: 0" align="left">
          <el-switch
            v-model="checkNothing[item.id]"
            @change="item.disable=!item.disable">
          </el-switch>
        </el-form-item>
        <el-form-item label="异常情况" style="margin-bottom: 0">
          <template>
            <el-checkbox-group v-model="item.val" align="left" :disabled="item.disable">
              <el-checkbox v-for="(option,i) in checkOptions[item.id]" :label="option.id" :key="i">{{option.detail}}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <el-form-item>
          <el-input v-model="item.remark" placeholder="请输入信息" :disabled="item.disable">
            <template slot="prepend">其他信息</template>
          </el-input>
        </el-form-item>
      </el-form>
      <!-- 危险废物管理 -->
      <el-form style="margin-top:24px;" label-width="100px">
        <div>
          <div class="checkTitle">危废暂存间</div>
          <el-form-item label="异常情况">
            <el-checkbox-group v-model="trashForm.room.option" align="left">
              <el-checkbox :disabled="item.id==0?null:trashForm.room.disable"
                @change="item.id==0?(trashForm.room.disable?trashForm.room.disable=false:(trashForm.room.disable=true,trashForm.room.option=[0],trashForm.room.other='')):null"
                v-for="item in roomOptions"
                :label="item.id"
                :key="item.id">{{item.detail}}</el-checkbox>
            </el-checkbox-group>
            <el-input :disabled="trashForm.room.disable" v-model="trashForm.room.other" placeholder="请输入内容">
              <template slot="prepend">其他信息</template>
            </el-input>
          </el-form-item>
          <!-- <div class="checkTitle">危险废物管理</div>
          <el-form-item label="异常情况">
            <el-checkbox-group v-model="trashForm.manage.option" align="left">
              <el-checkbox :disabled="item.id==0?null:trashForm.manage.disable"
                @change="item.id==0?(trashForm.manage.disable?trashForm.manage.disable=false:(trashForm.manage.disable=true,trashForm.manage.option=[0],trashForm.manage.other='')):null"
                v-for="item in manageOptions"
                :label="item.id"
                :key="item.id">{{item.detail}}</el-checkbox>
            </el-checkbox-group>
            <el-input :disabled="trashForm.manage.disable" v-model="trashForm.manage.other" placeholder="请输入内容">
              <template slot="prepend">其他信息</template>
            </el-input>
          </el-form-item> -->
        </div>
      </el-form>
      <el-button type="primary" @click="onSubmit">提交修改</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [
        {id: 0,title: '废气治理措施异常',val: [],remark: '',disable: false},
        {id: 1,title: '废水治理措施异常',val: [],remark: '',disable: false},
        {id: 2,title: '危险废物管理异常',val: [],remark: '',disable: false},
        {id: 3,title: '公司日常运行管理异常',val: [],remark: '',disable: false}
      ],
      checkNothing: [false,false,false,false],
      checkOptions: [
        [{id: 0,detail: '未制定设备运行台帐'},{id: 1,detail: '废气治理措施故障'},{id: 2,detail: '废气治理措施未开启'}],
        [{id: 0,detail: '未制定设备运行台帐'},{id: 1,detail: '废水治理措施故障'},{id: 2,detail: '废水治理措施未开启'}],
        [{id: 0,detail: '未建危废贮存间'},{id: 1,detail: '未制定危险废物管理台账'},{id: 2,detail: '危险废物管理制度未上墙'},{id: 3,detail: '未张贴危险废防治责任信息牌'},{id: 4,detail: '管理台账不完善'},{id: 5,detail: '危废转运不及时'},{id: 6,detail: '危废混合堆放'}],
        [{id: 0,detail: '未批先建'},{id: 1,detail: '生产线存在跑冒滴漏'},{id: 2,detail: '管理不规范'},{id: 3,detail: '卫生脏乱差'}]
      ],
      trashForm: {
        type: 1,
        room: {
          disable: false,
          option: [],
          other: ''
        },
      },
      roomOptions: [
        {id: 0,detail: '建设基本满足设计规范要求'},
        {id: 1,detail: '未设置危险废物标识牌'},
        {id: 2,detail: '危废贮存间未设置导流沟和收集池'},
        {id: 3,detail: '危废贮存间未建设防腐防渗'},
        {id: 4,detail: '未分类设置危险废物暂存区'}
      ],
      // manageOptions: [
      //   {id: 0,detail: '建设基本满足设计规范要求'},
      //   {id: 1,detail: '未建设危险废物管理台账'},
      //   {id: 2,detail: '危险废物管理制度未上墙'},
      //   {id: 3,detail: '未张贴危险废防治责任信息牌'}
      // ],
    }
  },
  methods: {
    onSubmit(){
      let that = this
      let form2 = this.form[2]

      let goods_type = form2.disable?0:1

      let goods_content = JSON.stringify(form2)
      let {room} = this.trashForm
      let status = true
      if(room.disable){
        var wfzcj_is = 1
      } else {
        var wfzcj_is = 2
      }
      // if(manage.disable){
      //   var jlfa_is = 1
      // } else {
      //   var jlfa_is = 2
      // }
      // if(room.option.length==0||manage.option.length==0){status=false}
      if(room.option.length==0){status=false}
      if(status){
        this.$http.get('api/scwrfz/updatewf?',{
          params: {
            company_id: that.firmInfo.company_id,
            goods_type: goods_type,
            goods_content: goods_content,
            wfzcj_is: wfzcj_is,
            wfzcj_content: JSON.stringify({option: room.option,other:room.other}),
            // jlfa_is: jlfa_is,
            // jlfa_content: JSON.stringify({option: manage.option,other: manage.other})
          }
        })
        .then(function(res) {
          console.log(res)
          if(res.data.code == 1){
            that.$message.success('提交成功！')
            that.$router.push('/move/list')
          } else {
            that.$message.warning('提交失败，请重试！')
          }
        })
      } else {
        this.$message.warning('信息填写不完整！')
      }
      
    },
    handleCheckAllChange(val) {
      console.log(val)
    },
  },
  created() {
    this.firmInfo = JSON.parse(window.sessionStorage.getItem('moveInfo_pollution'))
    // if(this.firmInfo.gas_type == 0){
    //   this.form[0].disable=true
    // } else {
    //   this.form[0].val = this.firmInfo.gas_content.val
    //   this.form[0].remark = this.firmInfo.gas_content.remark
    //   this.checkNothing[0]=true
    // }
    // if(this.firmInfo.water_type == 0){
    //   this.form[1].disable=true
    // } else {
    //   this.form[1].val = this.firmInfo.water_content.val
    //   this.form[1].remark = this.firmInfo.water_content.remark
    //   this.checkNothing[1]=true
    // }
    if(this.firmInfo.goods_type == 0){
      this.form[2].disable=true
    } else {
      this.form[2].val = JSON.parse(this.firmInfo.goods_content).val
      this.form[2].remark = JSON.parse(this.firmInfo.goods_content).remark
      this.checkNothing[2]=true
    }
    if(this.firmInfo.wfzcj_content!=null){
      this.trashForm.room.option = JSON.parse(this.firmInfo.wfzcj_content).option
      for(let i =0;i<JSON.parse(this.firmInfo.wfzcj_content).option.length;i++)
      {if(JSON.parse(this.firmInfo.wfzcj_content).option[i]==0){this.trashForm.room.disable = true}}
      this.trashForm.room.other = JSON.parse(this.firmInfo.wfzcj_content).other
    }

    // if(this.firmInfo.jlfa_content!=null){
    //   this.trashForm.manage.option = JSON.parse(this.firmInfo.jlfa_content).option
    //   for(let i =0;i<JSON.parse(this.firmInfo.jlfa_content).option.length;i++)
    //   {if(JSON.parse(this.firmInfo.jlfa_content).option[i]==0){this.trashForm.manage.disable = true}}
    //   this.trashForm.manage.other = JSON.parse(this.firmInfo.jlfa_content).other
    // }
    // if(this.firmInfo.qsxy_content!=null){this.trashForm.deal = this.firmInfo.qsxy_content}

    // if(this.firmInfo.usual_type == 0){
    //   this.form[3].disable=true
    // } else {
    //   this.form[3].val = this.firmInfo.usual_content.val
    //   this.form[3].remark = this.firmInfo.usual_content.remark
    //   this.checkNothing[3]=true
    // }
  },
}
</script>

<style>
  .checkWrap{
    margin-top: 30px;
    padding: 0 25%;
    box-sizing: border-box;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    color: #1F2F3D;
  }
  .checkTitle{
    margin-bottom: 20px;
  }
  .noNeed{
    color: #C0C4CC;
  }
  .uploadTitle{
    color: #606266;
    font-size: 16px;
    font-weight: 800;
  }
</style>