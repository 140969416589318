<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>异常列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="switchArea" v-if="userInfo.permission==1">
      <el-select v-model="region_id" placeholder="筛选地区" @change="getList()">
        <el-option
          v-for="item in regionList"
          :key="item.region_id"
          :label="item.region_name"
          :value="item.region_id">
        </el-option>
      </el-select>
    </div>
    <div class="listWrap">
      <el-button style="float:left;margin-bottom:10px;" @click="(isyc==0?isyc=1:isyc=0),getList()" type="danger" :plain="isyc==0" size="small">只看异常</el-button>
      <el-table
        :data="firmList.filter(data => !search || data.company_name.toLowerCase().includes(search.toLowerCase()))"
        style="width: 100%;">
        <el-table-column label="公司名称" prop="company_name" ></el-table-column>
        <el-table-column label="合规性管理异常" prop="yc_hgx" 
        :filter-method="filterHandler"
        :filters="[{text: '正常', value: 0},{text: '异常', value: 1}]">
          <template slot-scope="scope1">
            <el-tag
              class="tagBox"
              :type="scope1.row.yc_hgx === 0 ? 'primary' : 'danger'"
              disable-transitions>{{typeObj[scope1.row.yc_hgx]}}</el-tag>
              <el-button size="small" @click="navHgx(scope1.$index, scope1.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="自行监测管理异常" prop="yc_zxjc" 
        :filter-method="filterHandler"
        :filters="[{text: '正常', value: 0},{text: '异常', value: 1}]">
          <template slot-scope="scope2">
            <el-tag
              class="tagBox"
              :type="scope2.row.yc_zxjc === 0 ? 'primary' : 'danger'"
              disable-transitions>{{typeObj[scope2.row.yc_zxjc]}}</el-tag>
            <el-button size="small" @click="navZxjc(scope2.$index, scope2.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="危废管理异常" prop="yc_wf" 
        :filter-method="filterHandler"
        :filters="[{text: '正常', value: 0},{text: '异常', value: 1}]">
          <template slot-scope="scope3">
            <el-tag
              class="tagBox"
              :type="scope3.row.yc_wf === 0 ? 'primary' : 'danger'"
              disable-transitions>{{typeObj[scope3.row.yc_wf]}}</el-tag>
            <el-button size="small" @click="navWf(scope3.$index, scope3.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="日常运行管理异常" prop="yc_rcyx" 
        :filter-method="filterHandler"
        :filters="[{text: '正常', value: 0},{text: '异常', value: 1}]">
          <template slot-scope="scope4">
            <el-tag
              class="tagBox"
              :type="scope4.row.yc_rcyx === 0 ? 'primary' : 'danger'"
              disable-transitions>{{typeObj[scope4.row.yc_rcyx]}}</el-tag>
              <el-button size="small" @click="navRcyx(scope4.$index, scope4.row)">查看</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="right">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"/>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
        :total="last_page * 10">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firmList: [],
      search: '',
      typeObj: ['正常','异常'],
      currentPage: 1,
      last_page: 1,
      isyc: 0,
      region_id: '',
      regionList: [],
      userInfo: ''
    }
  },
  methods: {
    getList(){
      let that = this
      this.$http.get('api/query/unusualGet?page=' + that.currentPage + '&isyc=' + that.isyc + '&region_id=' + that.region_id)
      .then(function(res) {
        that.firmList = res.data.data.data
        console.log(res.data.data)
        if(res.data.code == 1){
          that.last_page = res.data.data.last_page
          if(that.isyc == 0){
            that.$message.success('获取列表成功！')
          } else {
            that.$message.success('获取列表(异常)成功！')
          }
        }
      })
    },
    navHgx(index, row) {
      window.sessionStorage.setItem('firmInfo_file', JSON.stringify(this.firmList[index]))
      this.$router.push({path: '/firm/info'})
    },
    navZxjc(index, row) {
      window.sessionStorage.setItem('firmInfo_exam', JSON.stringify(this.firmList[index].extra1))
      this.$router.push({path: '/exam/check'})
    },
    navWf(index, row) {
      window.sessionStorage.setItem('moveInfo_pollution', JSON.stringify(this.firmList[index].extra2))
      this.$router.push({path: '/move/check'})
    },
    navRcyx(index, row) {
      this.firmList[index].extra3.gas_content = JSON.parse(this.firmList[index].extra3.gas_content)
      this.firmList[index].extra3.usual_content = JSON.parse(this.firmList[index].extra3.usual_content)
      this.firmList[index].extra3.water_content = JSON.parse(this.firmList[index].extra3.water_content)
      window.sessionStorage.setItem('firmInfo_pollution', JSON.stringify(this.firmList[index].extra3))
      this.$router.push({path: '/pollution/check'})
    },
    handleCurrentChange(val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        that.regionList = res.data.data
        console.log(res.data.data)
        if(res.data.code != 1){
          that.$$message.warning('获取地区列表异常，请重试！')
        }
      })
    }
  },
  created() {
    this.getList()
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.getRegion()
  },
}
</script>

<style scoped>
  .listWrap{
    margin-top: 25px;
  }
  .table{
    height: 200px;
  }
  .el-pagination{
    margin-top: 30px;
    left: 50%;
  }
  .tagBox{
    margin-right: 5px;
  }
  .switchArea{
    position: absolute;
    right: 20px;
    margin-top: 10px;
  }
</style>