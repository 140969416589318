<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/exam/list' }">公司列表</el-breadcrumb-item>
      <el-breadcrumb-item>污染源排放</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="checkWrap">
      <!-- 有组织废气 -->
      <el-form class="formBox" :disabled="examInfo.zzfq==0" label-width="100px">
        <div class="checkTitle" v-if="examInfo.zzfq!=0">有组织废气</div>
        <div class="checkTitle noNeed" v-if="examInfo.zzfq==0">有组织废气 (无需检测)</div>
        <el-form-item label="监测情况" style="margin-bottom: 0">
          <el-radio-group v-model="checkForm.zzfq" style="float:left;" size="mini">
            <el-radio-button :label="1">合格</el-radio-button>
            <el-radio-button :label="2">不合格</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input v-model="checkForm.zzfq_reason" placeholder="如有需要请输入"></el-input>
        </el-form-item>
      </el-form>
      <!-- 无组织废气 -->
      <el-form class="formBox" :disabled="examInfo.wzzfq==0" label-width="100px">
        <div class="checkTitle" v-if="examInfo.wzzfq!=0">无组织废气</div>
        <div class="checkTitle noNeed" v-if="examInfo.wzzfq==0">无组织废气 (无需检测)</div>
        <el-form-item label="监测情况" style="margin-bottom: 0">
          <el-radio-group v-model="checkForm.wzzfq" style="float:left;" size="mini">
            <el-radio-button :label="1">合格</el-radio-button>
            <el-radio-button :label="2">不合格</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input v-model="checkForm.wzzfq_reason" placeholder="如有需要请输入"></el-input>
        </el-form-item>
      </el-form>
      <!-- 废水监测 -->
      <el-form class="formBox" :disabled="examInfo.fsjc==0" label-width="100px">
        <div class="checkTitle" v-if="examInfo.fsjc!=0">废水监测</div>
        <div class="checkTitle noNeed" v-if="examInfo.fsjc==0">废水监测 (无需检测)</div>
        <el-form-item label="监测情况" style="margin-bottom: 0">
          <el-radio-group v-model="checkForm.fsjc" style="float:left;" size="mini">
            <el-radio-button :label="1">合格</el-radio-button>
            <el-radio-button :label="2">不合格</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input v-model="checkForm.fsjc_reason" placeholder="如有需要请输入"></el-input>
        </el-form-item>
      </el-form>
      <!-- 噪声监测 -->
      <el-form class="formBox" :disabled="examInfo.zsjc==0" label-width="100px">
        <div class="checkTitle" v-if="examInfo.zsjc!=0">噪声监测</div>
        <div class="checkTitle noNeed" v-if="examInfo.zsjc==0">噪声监测 (无需检测)</div>
        <el-form-item label="监测情况" style="margin-bottom: 0">
          <el-radio-group v-model="checkForm.zsjc" style="float:left;" size="mini">
            <el-radio-button :label="1">合格</el-radio-button>
            <el-radio-button :label="2">不合格</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input v-model="checkForm.zsjc_reason" placeholder="如有需要请输入"></el-input>
        </el-form-item>
      </el-form>
      <!-- 周边环境监测 -->
      <el-form class="formBox" :disabled="examInfo.zbhjjc==0" label-width="100px">
        <div class="checkTitle" v-if="examInfo.zbhjjc!=0">周边环境监测</div>
        <div class="checkTitle noNeed" v-if="examInfo.zbhjjc==0">周边环境监测 (无需检测)</div>
        <el-form-item label="监测情况" style="margin-bottom: 0">
          <el-radio-group v-model="checkForm.zbhjjc" style="float:left;" size="mini">
            <el-radio-button :label="1">合格</el-radio-button>
            <el-radio-button :label="2">不合格</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input v-model="checkForm.zbhjjc_reason" placeholder="如有需要请输入"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="onSubmit">提交审核</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkForm: {
        zzfq: 1,
        wzzfq: 1,
        fsjc: 1,
        zsjc: 1,
        zbhjjc: 1
      },
      examInfo: []
    }
  },
  methods: {
    onSubmit(){
      let that = this
      let {
        zzfq,zzfq_reason,
        wzzfq,wzzfq_reason,
        fsjc,fsjc_reason,
        zsjc,zsjc_reason,
        zbhjjc,zbhjjc_reason
      } = this.checkForm
      if(this.examInfo.zzfq==0){zzfq = 0}
      if(this.examInfo.wzzfq==0){wzzfq = 0}
      if(this.examInfo.fsjc==0){fsjc = 0}
      if(this.examInfo.zsjc==0){zsjc = 0}
      if(this.examInfo.zbhjjc==0){zbhjjc = 0}
      this.$http.get('api/monitor/zxjcResultUpdate?',{
        params: {
          company_id: that.examInfo.company_id,
          zzfq: zzfq,
          zzfq_reason: zzfq_reason,
          wzzfq: wzzfq,
          wzzfq_reason: wzzfq_reason,
          fsjc: fsjc,
          fsjc_reason: fsjc_reason,
          zsjc: zsjc,
          zsjc_reason: zsjc_reason,
          zbhjjc: zbhjjc,
          zbhjjc_reason: zbhjjc_reason,
        }
      })
      .then(function(res) {
        console.log(res)
        if(res.data.code == 1){
          that.$message.success('提交成功！')
          that.$router.push('/exam/list')
        }
      })
    }
  },
  created() {
    this.examInfo = JSON.parse(window.sessionStorage.getItem('firmInfo_exam'))
    if(this.examInfo.is_write == 1){
      this.checkForm = this.examInfo.write_content
    }
  },
}
</script>

<style>
  .checkWrap{
    margin-top: 30px;
    padding: 0 25%;
    box-sizing: border-box;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    color: #1F2F3D;
  }
  .checkTitle{
    margin-bottom: 20px;
  }
  .noNeed{
    color: #C0C4CC;
  }
</style>