<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>创建用户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="infoWrap">
      <el-input placeholder="请输入账号" v-model="user_name" clearable class="infoWrap_input">
        <template slot="prepend"><div class="inputBox">账号</div></template>
      </el-input>
      <el-input placeholder="请输入地区" v-model="region_name" clearable class="infoWrap_input" v-if="userInfo.permission==1">
        <template slot="prepend"><div class="inputBox">地区</div></template>
      </el-input>
      <el-input placeholder="请输入姓名" v-model="real_name" clearable class="infoWrap_input">
        <template slot="prepend"><div class="inputBox">姓名</div></template>
      </el-input>
      <el-input placeholder="请输入身份证" v-model="idcard_no" clearable class="infoWrap_input">
        <template slot="prepend"><div class="inputBox">身份证</div></template>
      </el-input>
      <!-- <div class="radioBox">
        <template>
        <span class="radioBox_text">用户权限</span>
        <el-radio-group v-model="permission">
          <el-radio-button :label="2">地区管理员</el-radio-button>
          <el-radio-button :label="3">员工</el-radio-button>
        </el-radio-group>
        </template>
      </div> -->
      <div class="radioBox">
        <template>
        <span class="radioBox_text">用户性别</span>
        <el-radio-group v-model="sex">
          <el-radio-button :label="0">男</el-radio-button>
          <el-radio-button :label="1">女</el-radio-button>
        </el-radio-group>
        </template>
      </div>

    </div>
    <el-button type="primary" @click="submit" class="submit_btn">确认无误，点击创建</el-button>      
  </div>
</template>

<script>
export default {
  data() {
    return {
      user_name: '',
      region_name: '',
      real_name: '',
      idcard_no: '',
      permission: 3,
      sex: 0,
      userInfo: ''
    }
  },
  methods: {
    submit(){
      let {
        user_name,
        region_name,
        sex,
        real_name,
        idcard_no,
      } = this
      let that = this
      if(user_name!=''&&real_name!=''&&idcard_no!=''){
        this.$http.get('api/user/create',{
          params: {
            pwd: 123456,
            user_name: user_name,
            real_name: real_name,
            sex: sex,
            idcard_no: idcard_no,
            region_name: region_name
          }
        })
        .then(function(res) {
          console.log(res)
          if(res.data.code == 1){
            that.$message.success('创建成功！')
            that.$router.push('/user/list')
          } else if (res.data.code == 2){
            that.$message.warning('用户名重复，请重试！')
          }
        })
      } else {
        this.$message.warning('请完整填写信息')
      }
    }
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
  },
}
</script>

<style scoped>
  .infoWrap{
    padding: 20px 20% 0;
    box-sizing: border-box;
    display: inline-block;
  }
  .infoWrap_input{
    margin: 10px 0;
  }
  .inputBox{
    width: 60px;
  }
  .submit_btn{
    width: 200px;
    bottom: 20px;
    margin-top: 200px;
  }
  .radioBox{
    margin-top: 10px;
    float: left;
  }
  .radioBox_text{
    margin: 0 25px 0 24px;
    font-size: 15px;
    color: #6E7079;
  }
</style>