<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>用户信息</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="infoWrap">
      <el-descriptions class="margin-top" column="1" border>
        <template slot="extra">
          <el-button @click="navAlter" type="primary" size="small" v-if="mineInfo.permission == 1">修改</el-button>
        </template>
        <!-- <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-picture-outline-round"></i> 头像</div>            
          </template>
            <el-image
              class="descImg"
              :src="url" 
              :preview-src-list="srcList">
            </el-image>      
        </el-descriptions-item> -->
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-user"></i> 账号</div>
          </template>
          <div class="infoBox">{{userInfo.user_name}}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-location-outline"></i> 地区</div>
          </template>
          <div class="infoBox">{{region}}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-collection-tag"></i> 姓名</div>             
          </template>
          <div class="infoBox">{{userInfo.real_name}}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-male"></i> 性别</div>            
          </template>
          <div class="infoBox">{{sexObj[userInfo.sex]}}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-bank-card"></i> 身份证</div>            
          </template>
          <div class="infoBox">{{userInfo.idcard_no}}</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // url: 'http://localhost:8003/public/webpack-logo.png?project=VinzbQ0u6J',
      // srcList: [
      //   'http://localhost:8003/public/webpack-logo.png?project=VinzbQ0u6J'
      // ],
      userInfo: '',
      region: '',
      sexObj: ['男','女']
    }
  },
  methods: {
    navAlter(){
      this.$router.push('/user/alter')
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        console.log(res.data.data)
        for(let i = 0;i<res.data.data.length;i++){
          if(res.data.data[i].region_id == that.userInfo.region_id){
            that.region = res.data.data[i].region_name
          }
        }
      })
    },
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_file'))
    this.mineInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.getRegion()
  },
}
</script>

<style scoped>
  .infoWrap{
    margin-top: 20px;
  }
  .labelBox{
    min-width: 120px;
  }
  .descImg{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .infoBox{
    min-width: 1000px;
  }
</style>