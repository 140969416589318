<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/move/list' }">运转列表</el-breadcrumb-item>
      <el-breadcrumb-item>全部记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button class="addBtn" @click="handleAdd()" type="primary" size="small">添加</el-button>
    <div class="listWrap">
      <el-table
        :data="moveList"
        :default-sort = "[{prop: 'date', order: 'descending'}]"
        style="width: 100%;">
        <el-table-column label="运转日期" prop="date" sortable></el-table-column>
        <el-table-column label="运转公司" prop="company_zy"></el-table-column>
        <el-table-column label="危废编号" prop="sort"></el-table-column>
        <el-table-column label="运输量(吨)" prop="quantity"></el-table-column>
        <el-table-column
          align="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              @click="handleCheck(scope.$index, scope.row)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="dialogVisible = true,selectIndex = scope.$index">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="25%"
      show-close="false"
      :before-close="handleClose">
      <span>确定要删除该条记录吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDelete()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moveList: [],
      selectIndex: '',
      dialogVisible: false,
      moveInfo: ''
    }
  },
  methods: {
    handleAdd(index, row) {
      this.$router.push({path: '/move/add'})
    },
    handleCheck(index, row) {
      window.sessionStorage.setItem('moveInfo_alter', JSON.stringify(this.moveList[index]))
      this.$router.push({path: '/move/alter'})
    },
    handleDelete() {
      let that = this
      this.$http.get('api/wxfwgl/resultDelete?id='+this.moveList[this.selectIndex].id + '&company_id=' + that.moveInfo.company_id)
      .then(function(res) {
        console.log(res.data)
        if(res.data.code == 1){
          that.moveList.splice(that.selectIndex,1)
          that.dialogVisible = false
          that.$message.success('删除记录成功！')
        } else if(res.data.code == 2){
          that.dialogVisible = false
          that.$message.warning('该账户无权删除！')
        }
      })
    },
    handleClose(index, row) {
      this.$confirm('确认关闭？')
    },
    getList(){
      let that = this
      this.$http.get('api/wxfwgl/resultget?company_id=' + this.moveInfo.company_id)
      .then(function(res) {
        that.moveList = res.data.data
        console.log(res)
      })
    }
  },
  created() {
    this.moveInfo = JSON.parse(window.sessionStorage.getItem('moveInfo'))
    this.getList()
  },
}
</script>

<style scoped>
  .listWrap{
    margin-top: 50px;
  }
  .addBtn{
    position: absolute;
    right: 30px;
  }
  .el-pagination{
    margin-top: 30px;
    position: absolute;
    left: 50%;
    transform: translateY(-50%);
  }
</style>