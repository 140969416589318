<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>公司列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="switchArea" v-if="userInfo.permission==1">
      <el-select v-model="region_id" placeholder="筛选地区" @change="getList()">
        <el-option
          v-for="item in regionList"
          :key="item.region_id"
          :label="item.region_name"
          :value="item.region_id">
        </el-option>
      </el-select>
    </div>
    <el-button class="addBtn" @click="navAdd" type="primary" size="small" v-if="userInfo.permission!=1">创建</el-button>
    <div class="listWrap">
      <el-button style="float:left;margin-bottom:10px;" @click="(isyc==0?isyc=1:isyc=0),getList()" type="danger" :plain="isyc==0" size="small">只看异常</el-button>
      <el-table
        :data="firmList.filter(data => !search || data.company_name.toLowerCase().includes(search.toLowerCase()))"
        style="width:100%;">
        <el-table-column label="公司名称" prop="company_name" width="240px"></el-table-column>
        <el-table-column label="地区" prop="region_name" width="120px"></el-table-column>
        <el-table-column label="环境影响评价办理" prop="is_hjpj"
        column-key="is_hjpj"
        :filter-method="filterHandler"
        :filters="[{text: '已完成', value: 1},{text: '未完成', value: 0}]">
          <template slot-scope="scope1">
            <el-tag
              :type="scope1.row.is_hjpj === 1 ? 'primary' : 'danger'"
              disable-transitions>{{finishObj[scope1.row.is_hjpj]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="排污许可办理" prop="is_pwxk"
          :filter-method="filterHandler"
          :filters="[{text: '已完成', value: 1},{text: '未完成', value: 0}]">
          <template slot-scope="scope2">
            <el-tag
              :type="scope2.row.is_pwxk === 1 ? 'primary' : 'danger'"
              disable-transitions>{{finishObj[scope2.row.is_pwxk]}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="环境风险应急预案编制" prop="is_hjya"
          :filter-method="filterHandler"
          :filters="[{text: '已完成', value: 1},{text: '未完成', value: 0}]">
          <template slot-scope="scope3">
            <el-tag
              :type="scope3.row.is_hjya === 1 ? 'primary' : 'danger'"
              disable-transitions>{{finishObj[scope3.row.is_hjya]}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column label="项目环保验收办理" prop="is_hbys"
          :filter-method="filterHandler"
          :filters="[{text: '已完成', value: 1},{text: '未完成', value: 0}]">
          <template slot-scope="scope4">
            <el-tag
              :type="scope4.row.is_hbys === 1 ? 'primary' : 'danger'"
              disable-transitions>{{finishObj[scope4.row.is_hbys]}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column label="污染源排放监控管理" prop="type"
          :filter-method="filterHandler"
          :filters="[{text: '正常', value: 1},{text: '异常', value: 0}]">
          <template slot-scope="scope5">
            <el-tag
              :type="scope5.row.type === 0 ? 'primary' : 'danger'"
              disable-transitions>{{okObj[scope5.row.type]}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column
          align="right">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"/>
          </template>
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleCheck(scope.$index, scope.row)">查看</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="dialogVisible = true,selectIndex = scope.$index">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
        :total="last_page * 10">
      </el-pagination>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="25%"
      :before-close="handleClose">
      <span>确定要删除该公司吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="handleDelete()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firmList: [],
      region_id: '',
      regionList: [],
      currentPage: 1,
      last_page: 1,
      isyc: 0,
      dialogVisible: false,
      selectIndex: '',
      finishObj: ['未完成','已完成'],
      okObj: ['正常','异常'],
      search: '',
      userInfo: ''
    }
  },
  methods: {
    navAdd(){
      this.$router.push({path: '/firm/add'})
    },
    handleCheck(index, row) {
      console.log(index, row);
      window.sessionStorage.setItem('firmInfo_file', JSON.stringify(this.firmList[index]))
      this.$router.push({path: '/firm/info'})
    },
    handleDelete() {
      let that = this
      this.$http.get('api/company/delete?company_id=' + this.firmList[this.selectIndex].company_id)
      .then(function(res) {
        console.log(res.data)
        if(res.data.code == 1){
          that.firmList.splice(that.selectIndex,1)
          that.dialogVisible = false
          that.$message.success('删除公司成功！')
          that.getList()
        } else if(res.data.code == 2){
          that.dialogVisible = false
          that.$message.warning('该账户无权删除！')
        }
      })
    },
    handleClose(index, row) {
      this.$confirm('确认关闭？')
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    handleCurrentChange(val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        that.regionList = res.data.data
        console.log(res.data.data)
        if(res.data.code != 1){
          that.$$message.warning('获取地区列表异常，请重试！')
        }
      })
    },
    getList(){
      let that = this
      this.$http.get('api/company/get?page=' + that.currentPage + '&region_id=' + that.region_id + '&isyc=' + that.isyc)
      .then(function(res) {
        console.log(res.data.data)
        let data = res.data.data.data
        that.firmList = data
        for(let i=0;i<res.data.data.data.length;i++){
          if(data[i].is_onlinejc == 2||data[i].is_zxjc == 2||data[i].is_wxfwgl == 2){
            that.firmList[i].type = 1
          } else {
            that.firmList[i].type = 0
          }
        }
        that.last_page = res.data.data.last_page
        if(that.currentPage == 1){
          that.$message.success('获取列表成功！')
        }
      })
    }
  },
  created() {
    this.getList()
    this.getRegion()
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
  },
}
</script>
<style scoped>
  .listWrap{
    margin-top: 25px;
  }
  .addBtn{
    position: absolute;
    right: 30px;
  }
  .table{
    height: 200px;
  }
  .el-pagination{
    margin-top: 30px;
    left: 50%;
  }
  .switchArea{
    position: absolute;
    margin-top: 10px;
    right: 20px;
  }
</style>