<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/firm/list' }">公司列表</el-breadcrumb-item>
      <el-breadcrumb-item>公司详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="infoWrap">
      <el-descriptions class="margin-top" :column="1" border>
        <template slot="extra">
          <el-button @click="navAlter" type="primary" size="small">修改</el-button>
        </template>
        <!-- 第一表单开始 -->
        <el-descriptions-item>
          <div class="titleBox">公司基本信息</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-office-building"></i> 公司名称</div>
          </template>
          {{firmInfo.company_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-collection-tag"></i> 信用编号</div>            
          </template>
          {{firmInfo.credit_no}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-location-outline"></i> 注册地址</div>             
          </template>
          {{firmInfo.address}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-user"></i> 法人名称</div>            
          </template>
          {{firmInfo.legal_name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-mobile"></i> 法人联系方式</div>            
          </template>
          {{firmInfo.tel}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-picture-outline-round"></i> 营业执照照片</div>            
          </template>
          <el-image
            class="descImg"
            :src="preUrl+firmInfo.license" 
            :preview-src-list="[preUrl+firmInfo.license]"
            v-show="firmInfo.license!=''">
          </el-image>
          <div v-show="firmInfo.license==''">暂无营业执照</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-chat-line-round"></i> 公司介绍</div>            
          </template>
          {{firmInfo.introduce}}
        </el-descriptions-item>
        <!-- 第二表单开始 -->
        <el-descriptions-item>
          <div class="titleBox">环境影响评价办理情况 <span v-show="form1.type != 1">({{writeObj[form1.type]}})</span></div>
        </el-descriptions-item>
        <template v-if="form1.type==1">
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-pie-chart"></i> 文件类型</div>            
            </template>
            {{sortObj1[form1.sort-1]}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-document"></i> 批复文号</div>            
            </template>
            {{form1.approval_no}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-time"></i> 取得时间</div>            
            </template>
            {{form1.get_date}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-link"></i> 下载文件</div>            
            </template>
            <el-button icon="el-icon-download" el-icon-download @click="downLoadFile(1)" type="primary" size="mini">下载</el-button>
          </el-descriptions-item>
        </template>
        <!-- 第三表单开始 -->
        <el-descriptions-item>
          <div class="titleBox">排污许可办理情况 <span v-show="form2.type != 1">({{writeObj[form2.type]}})</span></div>
        </el-descriptions-item>
        <template v-if="form2.type==1">
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-pie-chart"></i> 许可分类</div>            
            </template>
            {{sortObj2[form2.sort-1]}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-document"></i> 办理编号</div>            
            </template>
            {{form2.approval_no}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-time"></i> 取得时间</div>            
            </template>
            {{form2.get_date}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-link"></i> 文件下载</div>            
            </template>
            <el-button icon="el-icon-download" el-icon-download @click="downLoadFile(2)" type="primary" size="mini">下载</el-button>
          </el-descriptions-item>
        </template>
        <!-- 第四表单开始 -->
        <el-descriptions-item>
          <div class="titleBox">环境风险应急预案编制情况 <span v-show="form3.type != 1">({{writeObj[form3.type]}})</span></div>
        </el-descriptions-item>
        <template v-if="form3.type==1">
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-link"></i> 下载文件</div>            
            </template>
            <el-button icon="el-icon-download" el-icon-download @click="downLoadFile(3)" type="primary" size="mini">下载</el-button>
          </el-descriptions-item>
        </template>
        <template v-if="form4.type==1">
          <el-descriptions-item>
            <div class="titleBox">项目环保验收办理情况 <span v-show="form4.type != 1">({{writeObj[form4.type]}})</span></div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-link"></i> 下载文件</div>            
            </template>
            <el-button icon="el-icon-download" el-icon-download @click="downLoadFile(4)" type="primary" size="mini">下载</el-button>
          </el-descriptions-item>
        </template>
        <!-- 第五表单开始 -->
        <el-descriptions-item>
          <div class="titleBox">自行监测情况</div>
        </el-descriptions-item>
        <template v-if="form5!=null">
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-add-location"></i> 已监测</div>            
            </template>
            <span v-show="form5.zzfq == 1"> 有组织废气 </span>
            <span v-show="form5.wzzfq == 1"> 无组织废气 </span>
            <span v-show="form5.fsjc == 1"> 废水监测 </span>
            <span v-show="form5.zsjc == 1"> 噪声监测 </span>
            <span v-show="form5.zbhjjc == 1"> 周边环境监测 </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-delete-location"></i> 应监管未监管</div>            
            </template>
            <span v-show="form5.zzfq == 2"> 有组织废气 </span>
            <span v-show="form5.wzzfq == 2"> 无组织废气 </span>
            <span v-show="form5.fsjc == 2"> 废水监测 </span>
            <span v-show="form5.zsjc == 2"> 噪声监测 </span>
            <span v-show="form5.zbhjjc == 2"> 周边环境监测 </span>
          </el-descriptions-item>
        </template>
        <!-- 第六表单开始 -->
        <el-descriptions-item>
          <div class="titleBox">在线监测情况</div>
        </el-descriptions-item>
        <template v-if="form6!=null">
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-cloudy"></i> 有组织废气</div>           
            </template>
            {{sortObj3[form6.yzzfq]}}
          </el-descriptions-item>
          <template v-if="form6.yzzfq == 0">
            <el-descriptions-item>
              <template slot="label">
                <div class="labelBox"><i class="el-icon-house"></i> 单位名称</div>            
              </template>
              <template v-if="form6.yzzfq_other!=null">
                {{form6.yzzfq_other[0].name}}
              </template>
              <template v-if="form6.yzzfq_other==null">
                暂未填写
              </template>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="labelBox"><i class="el-icon-link"></i> 联网链接</div>            
              </template>
              <template v-if="form6.yzzfq_other!=null">
                {{form6.yzzfq_other[0].link}}
              </template>
              <template v-if="form6.yzzfq_other==null">
                暂未填写
              </template>
            </el-descriptions-item>
          </template>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-aim"></i> 无组织废气</div>            
            </template>
            {{sortObj3[form6.wzzfq]}}
          </el-descriptions-item>
          <template v-if="form6.wzzfq == 0">
            <el-descriptions-item>
              <template slot="label">
                <div class="labelBox"><i class="el-icon-house"></i> 单位名称</div>            
              </template>
              <template v-if="form6.yzzfq_other!=null">
                {{form6.wzzfq_other[0].name}}
              </template>
              <template v-if="form6.yzzfq_other==null">
                暂未填写
              </template>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="labelBox"><i class="el-icon-link"></i> 联网链接</div>            
              </template>
              <template v-if="form6.yzzfq_other!=null">
                {{form6.wzzfq_other[0].link}}
              </template>
              <template v-if="form6.yzzfq_other==null">
                暂未填写
              </template>
            </el-descriptions-item>
          </template>
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-bangzhu"></i> 废水监测</div>            
            </template>
            {{sortObj3[form6.fsgl]}}
          </el-descriptions-item>
          <template v-if="form6.fsgl == 0">
            <el-descriptions-item>
              <template slot="label">
                <div class="labelBox"><i class="el-icon-house"></i> 单位名称</div>            
              </template>
              <template v-if="form6.yzzfq_other!=null">
                {{form6.fsgl_other[0].name}}
              </template>
              <template v-if="form6.yzzfq_other==null">
                暂未填写
              </template>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="labelBox"><i class="el-icon-link"></i> 联网链接</div>            
              </template>
              <template v-if="form6.yzzfq_other!=null">
                {{form6.fsgl_other[0].link}}
              </template>
              <template v-if="form6.yzzfq_other==null">
                暂未填写
              </template>
            </el-descriptions-item>
          </template>
        </template>
        <!-- 第七表单开始 -->
        <el-descriptions-item>
          <div class="titleBox">危险废物管理</div>
        </el-descriptions-item>
        <template v-if="form7!=null">
          <el-descriptions-item>
            <template slot="label">
              <div class="labelBox"><i class="el-icon-stopwatch"></i> 建设状态</div>           
            </template>
            {{sortObj4[form7.type]}}
          </el-descriptions-item>
          <template v-if="form7.type==1">
            <el-descriptions-item v-if="form7.wfzcj_content!=null&&form7.wfzcj_is!=1">
              <template slot="label">
                <div class="labelBox"><i class="el-icon-link"></i> 危废暂存间</div>            
              </template>
              <el-tag
              class="infoTag"
              type="warning"
              v-for="(item,i) in form7.wfzcj_content.option"
              :key="i">{{roomOptions[item]}}</el-tag>
              <el-tag class="infoTag" type="warning">{{form7.wfzcj_content.other}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item v-if="form7.jlfa_content!=null&&form7.jlfa_is!=1">
              <template slot="label">
                <div class="labelBox"><i class="el-icon-copy-document"></i> 危险废物管理</div>            
              </template>
              <el-tag
              class="infoTag"
              v-for="(item,i) in form7.jlfa_content.option"
              :key="i">{{manageOptions[item]}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div class="labelBox"><i class="el-icon-collection"></i> 处置协议</div>            
              </template>
              {{form7.qsxy_content.length}} 个单位
            </el-descriptions-item>
            <el-descriptions-item v-for="(item,i) in form7.qsxy_content" :key="i">
              <template slot="label">
                <div class="labelBox"><i class="el-icon-link"></i> 单位 {{i+1}}</div>
              </template>
              <el-tag class="infoTag" type="info">单位名称: {{item.name}}</el-tag>
              <el-tag class="infoTag" type="info">许可证: {{item.license}}</el-tag>
              <el-tag class="infoTag" type="info" v-for="subItem in item.sort" :key="subItem.id">编号{{subItem.id + 1}}: {{subItem.val}}</el-tag>
            </el-descriptions-item>
          </template>


        </template>
      </el-descriptions>
    </div>
    <iframe name="tempiframe" style="display:none;"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      preUrl :'https://jingquekejifuwu.powerv.top/upload/license/',
      sortObj1: ['报告书','报告表','登记管理'],
      sortObj2: ['重点管理','简化管理','登记管理'],
      sortObj3: ['无需建设','已建设','未建设'],
      sortObj4: ['无需管理','已实施管理','未实施管理'],
      roomOptions: ['建设基本满足设计规范要求','未设置危险废物标识牌','危废贮存间未设置导流沟和收集池','危废贮存间未建设防腐防渗','未分类设置危险废物暂存区'],
      manageOptions: ['建设基本满足设计规范要求','未建设危险废物管理台账','危险废物管理制度未上墙','未张贴危险废防治责任信息牌'],
      writeObj: ['未填写','已办理','未办理','无需填写'],
      firmInfo: [],
      form1: [],
      form2: [],
      form3: [],
      form4: [],
      form5: [],
      form6: [],
      form7: [],
      show1: false
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
      .then(_ => {
        done();
      })
      .catch(_ => {});
    },
    navAlter(){
      let formData = [this.form1,this.form2,this.form5,this.form6,this.form7,this.form3,this.form4]
      window.sessionStorage.setItem('firmInfo_alter', JSON.stringify(formData))
      this.$router.push('/firm/alter')
    },
    getInfo(){
      let that = this
      let company_id = this.firmInfo.company_id
      this.$http.get('api/hpzzsh/hjpjGet?company_id=' + company_id)
      .then(function(res) {
        that.form1=res.data.data
        console.log(res.data.data)
        that.$http.get('api/hpzzsh/pwxkGet?company_id=' + company_id)
        .then(function(res) {
          that.form2=res.data.data
          console.log(res.data.data)
          that.$http.get('api/hpzzsh/hjyaGet?company_id=' + company_id)
          .then(function(res) {
            that.form3=res.data.data
            console.log(res.data.data)
            that.$http.get('api/hpzzsh/hbysGet?company_id=' + company_id)
            .then(function(res) {
              that.form4=res.data.data
              console.log(res.data.data)
              that.$http.get('api/monitor/zxjcGet?company_id=' + company_id)
              .then(function(res) {
                that.form5=res.data.data
                console.log(res.data.data)
                that.$http.get('api/monitor/onlinejcGet?company_id=' + company_id)
                .then(function(res) {
                  that.form6=res.data.data
                  console.log(res.data.data)
                  if(res.data.data!=null){
                    that.form6.fsgl_other=JSON.parse(res.data.data.fsgl_other)
                    that.form6.wzzfq_other=JSON.parse(res.data.data.wzzfq_other)
                    that.form6.yzzfq_other=JSON.parse(res.data.data.yzzfq_other)
                  }
                  that.$http.get('api/wxfwgl/get?company_id=' + company_id)
                  .then(function(res) {
                    that.form7=res.data.data
                    console.log(res.data.data)
                    if(res.data.data!=null){
                      that.form7.jlfa_content=JSON.parse(res.data.data.jlfa_content)
                      that.form7.qsxy_content=JSON.parse(res.data.data.qsxy_content)
                      that.form7.wfzcj_content=JSON.parse(res.data.data.wfzcj_content)
                    }
                  })
                })
              })
            })
          })
        })
      })
    },
    downLoadFile(i) {
      var preUrl1 = 'https://jingquekejifuwu.powerv.top/upload/check/hjpj/'
      var preUrl2 = 'https://jingquekejifuwu.powerv.top/upload/check/pwxk/'
      var preUrl3 = 'https://jingquekejifuwu.powerv.top/upload/check/hjya/'
      var preUrl4 = 'https://jingquekejifuwu.powerv.top/upload/check/hbys/'
      console.log(preUrl1 + this.form1.file_path[0])
      if(i == 1){
        var url = preUrl1 + this.form1.file_path[0]
        var name = '环境影响评价办理情况-' + this.firmInfo.company_name 
      } else if(i == 2){
        var url = preUrl2 + this.form2.file_path
        var name = '排污许可办理情况-' + this.firmInfo.company_name 
      } else if(i == 3){
        var url = preUrl3 + this.form3.file_path[0]
        var name = '环境风险应急预案编制情况-' + this.firmInfo.company_name 
      } else if(i == 4){
        var url = preUrl4 + this.form4.file_path
        var name = '项目环保验收办理情况-' + this.firmInfo.company_name 
      }
      console.log(url)
      let suffix = url.substring(url.lastIndexOf("."), url.length);
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = 'blob';
      x.onload=function(e) {
          const url = window.URL.createObjectURL(x.response);
          const a = document.createElement('a');
          a.href = url;
          a.download = name + suffix;
          a.click()
      };
      x.send();
    },
  },
  created() {
    this.firmInfo = JSON.parse(window.sessionStorage.getItem('firmInfo_file'))
    this.getInfo()
  },
}
</script>

<style scoped>
  .labelBox{
    min-width: 120px;
  }
  .titleBox{
    text-align: center;
    font-size: 16px;
    font-weight: 800;
  }
  .descImg{
    width: 20%;
    border-radius: 8px;
  }
  .infoTag{
    margin: 5px 5px 0 0;
  }
</style>