<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/move/list' }">运转列表</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/move/info' }">全部记录</el-breadcrumb-item>
      <el-breadcrumb-item>修改记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="checkWrap">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="签署公司" align="left">
          <el-select v-model="company_zy" placeholder="请选择" @change="sort=''">
            <el-option
              v-for="(item,i) in firmOptions"
              :key="i"
              :label="item.name"
              :value="i">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="危废编号" align="left">
          <el-select v-model="sort" placeholder="请选择">
            <el-option
              v-for="item in firmOptions[company_zy].sort"
              :key="item.id"
              :label="item.val"
              :value="item.val">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转运时间">
          <el-col>
            <el-date-picker value-format="yyyy-M-d" type="date" placeholder="选择日期" v-model="form.date" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="运输重量">
          <el-input v-model="form.quantity" placeholder="请填写运输量 (单位：吨)"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">点击修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        date: '',
        quantity: ''
      },
      firmOptions: [],
      sortOptions: [],
      company_zy: 0,
      sort: '',
      moveInfo: '',
    }
  },
  methods: {
    onSubmit(){
      let that = this
      let {date,quantity} = this.form
      let {company_zy,sort,moveInfo} = this
      if(date!=''||quantity!=''||ompany_zy!=''||sort!=''){
        this.$http.get('api/wxfwgl/resultAdd?',{
          params: {
            company_id: moveInfo.company_id,
            company_zy: that.firmOptions[company_zy].name,
            sort: sort,
            quantity: quantity,
            date: date
          }
        })
        .then(function(res) {
          console.log(res)
          if(res.data.code == 1){
            that.$message.success('添加记录成功！')
            that.$router.push('/move/list')
          } else if(res.data.code == 2){
            that.$message.warning('未找到与之签署协议的公司！')
          }
        })
      } else {
        this.$message.warning('信息填写不完整！')
      }

    }
  },
  mounted() {
    this.moveInfo = JSON.parse(window.sessionStorage.getItem('moveInfo_alter'))
    this.form.date = this.moveInfo.date
    this.form.quantity = this.moveInfo.quantity
    this.sort = this.moveInfo.sort
    this.firmOptions = JSON.parse(JSON.parse(window.sessionStorage.getItem('moveInfo')).qsxy_content)
    for(let i = 0;i<this.firmOptions.length;i++){
      if(this.moveInfo.company_zy==this.firmOptions[i].name){
        this.company_zy = i
      }
    }
  },
}
</script>

<style>
  .checkWrap{
    margin-top: 40px;
    padding: 0 25%;
    box-sizing: border-box;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    color: #1F2F3D;
  }
  .checkTitle{
    margin-bottom: 20px;
  }
  .noNeed{
    color: #C0C4CC;
  }
</style>