<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="switchArea" v-if="userInfo.permission==1">
      <el-select v-model="region_id" placeholder="筛选地区" @change="getList()">
        <el-option
          v-for="item in regionList"
          :key="item.region_id"
          :label="item.region_name"
          :value="item.region_id">
        </el-option>
      </el-select>
    </div>
    <el-button v-if="addShow" class="addBtn" @click="navAdd" type="primary" size="small">创建</el-button>
    <div class="listWrap">
      <el-table
        :data="userList"
        style="width: 100%;">
        <el-table-column label="姓名" prop="real_name"></el-table-column>
        <el-table-column label="性别" prop="sex">
          <template slot-scope="scope"><div>{{sexObj[scope.row.sex]}}</div></template>
        </el-table-column>
        <el-table-column label="权限" prop="permission">
          <template slot-scope="scope"><div>{{permissionObj[scope.row.permission-1]}}</div></template>
        </el-table-column>
        <el-table-column
          align="right">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"/>
          </template>
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleCheck(scope.$index, scope.row)">查看</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="dialogVisible = true,selectIndex = scope.$index">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
        :total="last_page * 10">
      </el-pagination>
    </div>
    <el-dialog
      title="确认"
      width="25%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <span>确定要删除该用户吗</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDelete()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userList: [],
      selectIndex: '',
      currentPage: 1,
      last_page: 1,
      addShow: false,
      dialogVisible: false,
      region_id: '',
      regionList: [],
      search: '',
      sexObj: ['男','女'],
      permissionObj: ['总管理员','地区管理员','员工'],
    }
  },
  methods: {
    navAdd(){
      this.$router.push({path: '/user/add'})
    },
    handleCheck(index, row) {
      console.log(index, row);
      window.sessionStorage.setItem('userInfo_file', JSON.stringify(this.userList[index]))
      this.$router.push({path: '/user/info'})
    },
    handleDelete() {
      let that = this
      this.$http.get('api/user/delete?id=' + this.userList[this.selectIndex].user_id)
      .then(function(res) {
        console.log(res.data)
        if(res.data.code == 1){
          that.userList.splice(that.selectIndex,1)
          that.dialogVisible = false
          that.$message.warning('删除用户成功！')
        } else if(res.data.code == 2){
          that.dialogVisible = false
          that.$message.warning('该账户无权删除！')
        }
      })
    },
    handleClose(index, row) {
      this.$confirm('确认关闭？')
    },
    handleCurrentChange(val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    getList(){
      let that = this
      this.$http.get('api/user/get?page=' + that.currentPage + '&region_id=' + that.region_id)
      .then(function(res) {
        console.log(res.data.data)
        that.userList = res.data.data.data
        that.last_page = res.data.data.last_page
        if(that.currentPage == 1){
          that.$message.success('获取列表成功！')
        }
      })
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        that.regionList = res.data.data
        console.log(res.data.data)
        if(res.data.code != 1){
          that.$$message.warning('获取地区列表异常，请重试！')
        }
      })
    }
  },
  created() {
    let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    if(userInfo.permission != 3){
      this.addShow = true
    }
    this.getRegion()
    this.getList()
  },
}
</script>
<style scoped>
  .listWrap{
    margin-top: 50px;
  }
  .addBtn{
    position: absolute;
    right: 30px;
  }
  .table{
    height: 200px;
  }
  .el-pagination{
    margin-top: 30px;
    left: 50%;
  }
  .switchArea{
    position: absolute;
    margin-top: -5px;
    right: 100px;
  }
</style>