<template>
  <div class="login_wrap">
    <img class="login_bg" src="../assets/img/bg.png" alt="">
    <div class="login_box">
      <div class="login_header">
        <img src="../assets/img/band_text.jpg" alt="">
        <span>科信环保管家信息管理平台</span>
      </div>
      <!--表单提交区域-->
      <el-form :rules="loginFormRules" ref="loginFormRef" label-width="0px" class="login_form" :model="loginForm">
        <!--用户名-->
        <el-form-item prop="account">
          <el-input v-model="loginForm.account" placeholder="请输入账号" clearable></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="loginForm.password" show-password></el-input>
        </el-form-item>
        <!--按钮区-->
        <el-form-item class="btns">
          <el-button type="primary" @click="login" :loading="loginLoading" class="login_btn">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Login',
    data() {
    return {
      loginLoading: false, // 登录限制
      loginForm: {
        // 登录的表单数据的绑定对象
        account: '',
        password: ''
      },
      loginFormRules: {
        // 验证用户名是否合法
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    login() {
      let that = this
      if(this.loginForm.account != '' && this.loginForm.password != ''){
        this.loginLoading = true
        this.$http.get('api/base/login?account=' + this.loginForm.account + '&password=' + this.loginForm.password)
          .then(function(res) {
              console.log(res.data);
              if(res.data.code === 1){
                that.$message.success('登录成功!')
                window.sessionStorage.setItem('userInfo_mine', JSON.stringify(res.data.data))
                that.loginLoading = false
                that.$router.push('/welcome')
              } else if(res.data.code === 2){
                that.$message.error('账号或密码错误!')
                that.loginForm = {
                  account: '',
                  password: ''
                } 
                that.loginLoading = false
              }
          })
          .catch(function(error) {
              console.log(error);
          });
      } else {
        that.$message.warning('请输入账号及密码!')
      }
    }
  }
}
</script>

<style scoped>
.login_bg{
  position: absolute;
  width: 100%;
  height: 100%;
}
.login_wrap{
  background: #ECEEFF;
  width: 100%;
  height: 100%;
  background-image: url('');
}
.login_box{
  position: absolute;
  border-radius: 10px;
  width: 400px;
  height: 300px;
  background: #fff;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.login_header{
  width: 100%;
  height: 60px;
  background: #F5F5F5;
  overflow: hidden;
}
.login_header img{
  position: absolute;
  width: 120px;
  height: 70px;
  margin-top: -5px;
}
.login_header span{
  margin-left: 146px;
  line-height: 56px;
  font-size: 18px;
  color: rgba(0,0,0,0.3);
  letter-spacing: 1px;
  font-weight: 600;
}
.login_form {
  margin-top: 30px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 25px;
}
.login_btn{
  width: 100%;
  background: #6AB994;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
}
.el-button:hover{
  background: #509081;
}
</style>