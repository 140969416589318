<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/pollution/list' }">公司异常</el-breadcrumb-item>
      <el-breadcrumb-item>异常管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="checkWrap">
      <el-form class="formBox" label-width="100px" v-for="item in form" :key="item.id" v-show="item.id!=2">
        <div class="checkTitle">{{item.title}}</div>
        <el-form-item label="存在异常" style="margin-bottom: 0" align="left">
          <el-switch
            v-model="checkNothing[item.id]"
            @change="item.disable=!item.disable">
          </el-switch>
        </el-form-item>
        <el-form-item label="异常情况" style="margin-bottom: 0">
          <template>
            <el-checkbox-group v-model="item.val" align="left" :disabled="item.disable">
              <el-checkbox v-for="(option,i) in checkOptions[item.id]" :label="option.id" :key="i">{{option.detail}}</el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <el-form-item label="其他信息">
          <el-input v-model="item.remark" placeholder="请输入信息" :disabled="item.disable"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="onSubmit">提交修改</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [
        {id: 0,title: '废气治理措施异常',val: [],remark: '',disable: false},
        {id: 1,title: '废水治理措施异常',val: [],remark: '',disable: false},
        {id: 2,title: '危险废物管理异常',val: [],remark: '',disable: false},
        {id: 3,title: '公司日常运行管理异常',val: [],remark: '',disable: false}
      ],
      checkNothing: [false,false,false,false],
      checkOptions: [
        [{id: 0,detail: '未制定设备运行台帐'},{id: 1,detail: '废气治理措施故障'},{id: 2,detail: '废气治理措施未开启'}],
        [{id: 0,detail: '未制定设备运行台帐'},{id: 1,detail: '废水治理措施故障'},{id: 2,detail: '废水治理措施未开启'}],
        [{id: 0,detail: '未建危废贮存间'},{id: 1,detail: '未制定危险废物管理台账'},{id: 2,detail: '危险废物管理制度未上墙'},{id: 3,detail: '未张贴危险废防治责任信息牌'},{id: 4,detail: '管理台账不完善'},{id: 5,detail: '危废转运不及时'},{id: 6,detail: '危废混合堆放'}],
        [{id: 0,detail: '未批先建'},{id: 1,detail: '生产线存在跑冒滴漏'},{id: 2,detail: '管理不规范'},{id: 3,detail: '卫生脏乱差'}]
      ]
    }
  },
  methods: {
    onSubmit(){
      let that = this
      let form0 = this.form[0]
      let form1 = this.form[1]
      // let form2 = this.form[2]
      let form3 = this.form[3]
      let gas_type = form0.disable?0:1
      let water_type = form1.disable?0:1
      // let goods_type = form2.disable?0:1
      let usual_type = form3.disable?0:1
      // for(let i=0;i<form0.val.length;i++){form0.val[i] = this.checkOptions[0][i].detail}
      // for(let i=0;i<form1.val.length;i++){form1.val[i] = this.checkOptions[1][i].detail}
      // for(let i=0;i<form2.val.length;i++){form2.val[i] = this.checkOptions[2][i].detail}
      // for(let i=0;i<form3.val.length;i++){form3.val[i] = this.checkOptions[3][i].detail}
      let gas_content = JSON.stringify(form0)
      let water_content = JSON.stringify(form1)
      // let goods_content = JSON.stringify(form2)
      console.log(water_content)
      let usual_content = JSON.stringify(form3)
      this.$http.get('api/scwrfz/update?',{
        params: {
          company_id: that.firmInfo.company_id,
          gas_type: gas_type,
          water_type: water_type,
          // goods_type: goods_type,
          usual_type: usual_type,
          gas_content: gas_content,
          water_content: water_content,
          // goods_content: goods_content,
          usual_content: usual_content,
        }
      })
      .then(function(res) {
        console.log(res)
        if(res.data.code == 1){
          that.$message.success('提交成功！')
          that.$router.push('/pollution/list')
        } else {
          that.$message.warning('提交失败，请重试！')
        }
      })      
    },
    handleCheckAllChange(val) {
      console.log(val)
    },
  },
  created() {
    this.firmInfo = JSON.parse(window.sessionStorage.getItem('firmInfo_pollution'))
    console.log(JSON.parse(window.sessionStorage.getItem('firmInfo_pollution')))
    if(this.firmInfo.gas_type == 0){
      this.form[0].disable=true
    } else {
      this.form[0].val = this.firmInfo.gas_content.val
      this.form[0].remark = this.firmInfo.gas_content.remark
      this.checkNothing[0]=true
    }
    if(this.firmInfo.water_type == 0){
      this.form[1].disable=true
    } else {
      this.form[1].val = this.firmInfo.water_content.val
      this.form[1].remark = this.firmInfo.water_content.remark
      this.checkNothing[1]=true
    }
    if(this.firmInfo.goods_type == 0){
      this.form[2].disable=true
    } else {
      this.form[2].val = this.firmInfo.goods_content.val
      this.form[2].remark = this.firmInfo.goods_content.remark
      this.checkNothing[2]=true
    }
    if(this.firmInfo.usual_type == 0){
      this.form[3].disable=true
    } else {
      this.form[3].val = this.firmInfo.usual_content.val
      this.form[3].remark = this.firmInfo.usual_content.remark
      this.checkNothing[3]=true
    }
  },
}
</script>

<style>
  .checkWrap{
    margin-top: 30px;
    padding: 0 25%;
    box-sizing: border-box;
    font-size: 18px;
    display: inline-block;
    width: 100%;
    color: #1F2F3D;
  }
  .checkTitle{
    margin-bottom: 20px;
  }
  .noNeed{
    color: #C0C4CC;
  }
</style>