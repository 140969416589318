<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/exam/list' }">公司列表</el-breadcrumb-item>
      <el-breadcrumb-item>历史记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="listWrap">
      <el-table
        :data="firmList"
        style="width:100%;">
        <el-table-column label="审核日期" prop="date" width="240px"></el-table-column>
        <el-table-column label="有组织废气" prop="zzfq"
        column-key="zzfq"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope1">
            <el-tag
              v-if="scope1.row.zzfq!=0"
              :type="typeObj[scope1.row.zzfq].type"
              disable-transitions>{{typeObj[scope1.row.zzfq].detail}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="无组织废气" prop="wzzfq"
        column-key="wzzfq"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope2">
            <el-tag
              v-if="scope2.row.wzzfq!=0"            
              :type="typeObj[scope2.row.wzzfq].type"
              disable-transitions>{{typeObj[scope2.row.wzzfq].detail}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column label="废水监测" prop="fsjc"
        column-key="fsjc"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope3">
            <el-tag
              v-if="scope3.row.fsjc!=0"
              :type="typeObj[scope3.row.fsjc].type"
              disable-transitions>{{typeObj[scope3.row.fsjc].detail}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column label="噪声监测" prop="zsjc"
        column-key="zsjc"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope4">
            <el-tag
              v-if="scope4.row.zsjc!=0"
              :type="typeObj[scope4.row.zsjc].type"
              disable-transitions>{{typeObj[scope4.row.zsjc].detail}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="周边环境监测" prop="zbhjjc"
        column-key="zbhjjc"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope5">
            <el-tag
              v-if="scope5.row.zbhjjc!=0"
              :type="typeObj[scope5.row.zbhjjc].type"
              disable-transitions>{{typeObj[scope5.row.zbhjjc].detail}}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeObj: [
        {},
        {type: 'primary',detail: '合格'},
        {type: 'danger',detail: '不合格'}
      ],
      firmList: [],
    }
  },
  methods: { 
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    getList(){
      let that = this
      this.$http.get('api/monitor/zxjcResultGet?company_id=' + that.examInfo.company_id)
      .then(function(res) {
        console.log(res.data.data)
        that.firmList = res.data.data
        if(res.data.code == 1){
          that.$message.success('获取历史记录成功！')
        }
      })
    }
  },
  created() {
    this.examInfo = JSON.parse(window.sessionStorage.getItem('firmInfo_exam'))
    this.getList()
  },
}
</script>
<style scoped>
  .listWrap{
    margin-top: 50px;
  }
  .addBtn{
    position: absolute;
    right: 30px;
  }
  .table{
    height: 200px;
  }
  .el-pagination{
    margin-top: 30px;
    position: absolute;
    left: 50%;
    transform: translateY(-50%);
  }
</style>