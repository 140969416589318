<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/mine/info' }">我的账户</el-breadcrumb-item>
      <el-breadcrumb-item>修改信息</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="infoWrap">
      <el-input placeholder="请输入新的密码" type="password" v-model="pwd1" clearable class="infoWrap_input" show-password></el-input>
      <el-input placeholder="请再次输入密码" type="password" v-model="pwd2" clearable class="infoWrap_input" show-password></el-input>
    </div>
    <el-button type="primary" @click="submit" class="submit_btn">提交修改</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pwd1: '',
      pwd2: '',
      userInfo: ''
    }
  },
  methods: {
    submit(){
      let that = this
      if(this.pwd1!=''&&this.pwd2!=''){
        if(this.pwd1==this.pwd2){
          this.$http.get('api/user/updatePwd?id='+this.userInfo.user_id+'&password='+ this.pwd2)
          .then(function(res) {
            if(res.data.code == 1){
              that.$message.success('密码修改成功！')
              that.$router.push('/mine/info')
            }
          })
        } else {
          this.$message.warning('两次密码输入不一致!')
        }
      } else {
        this.$message.warning('密码不能为空！')
      }

    }
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
  },
}
</script>

<style scoped>
  .infoWrap{
    padding: 20px 20% 0;
    box-sizing: border-box;
  }
  .infoWrap_input{
    margin: 10px 0;
  }
  .inputBox{
    width: 80px;
  }
  .submit_btn{
    width: 200px;
    position: absolute;
    bottom: 20px;
    transform: translate(-50%,-50%);
  }
</style>