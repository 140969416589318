import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './utils/eventBus.js'
import './plugins/element.js'
import jqueryForm from 'jquery-form'
Vue.use(jqueryForm)
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入全局样式
import './assets/css/app.css'
// 引入Echarts
import * as echarts from 'echarts'
import axios from 'axios'
axios.defaults.baseURL = 'https://jingquekejifuwu.powerv.top/'
// axios.defaults.baseURL = 'api/'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
