<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/analsis/chart' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>运转列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="switchArea" v-if="userInfo.permission==1">
      <el-select v-model="region_id" placeholder="筛选地区" @change="getList()">
        <el-option
          v-for="item in regionList"
          :key="item.region_id"
          :label="item.region_name"
          :value="item.region_id">
        </el-option>
      </el-select>
    </div>
    <div class="listWrap">
      <el-table
        :default-sort = "[{prop: 'date', order: 'descending'},{prop: 'count', order: 'descending'}]"
        :data="moveList.filter(data => !search || data.company_name.toLowerCase().includes(search.toLowerCase()))"
        style="width: 100%;">
        <el-table-column label="公司名称" prop="company_name" width="360px"></el-table-column>
        <el-table-column label="最近运转记录" prop="date" sortable >
          <template slot-scope="scope"><div>{{scope.row.date==''?'暂无运转记录':scope.row.date}}</div></template>
        </el-table-column>
        <el-table-column label="运转次数" prop="count" sortable></el-table-column>
        <el-table-column label="危险废物管理异常" prop="is_wxfwgl"
        :filter-method="filterHandler"
        :filters="[{text: '正常', value: 1},{text: '异常', value: 2}]">
          <template slot-scope="scope3">
            <el-tag
              v-if="scope3.row.is_wxfwgl!=0"
              :type="typeObj[scope3.row.is_wxfwgl].type"
              disable-transitions>{{scope3.row.is_wxfwgl==1?'正常':'异常'}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column
          align="right">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"/>
          </template>
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleCheck(scope.$index, scope.row)">查看记录</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleAdd(scope.$index, scope.row)">管理异常</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
        :total="last_page * 10">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeObj: [
        {},
        {type: 'primary'},
        {type: 'danger'},
      ],
      moveList: [],
      search: '',
      currentPage: 1,
      last_page: 1,
      region_id: '',
      regionList: [],
      userInfo: ''
    }
  },
  methods: {
    handleCheck(index, row) {
      window.sessionStorage.setItem('moveInfo', JSON.stringify(this.moveList[index]))
      this.$router.push({path: '/move/info'})
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    handleAdd(index, row) {
      window.sessionStorage.setItem('moveInfo_pollution', JSON.stringify(this.moveList[index]))
      this.$router.push({path: '/move/check'})
    },
    handleCurrentChange(val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    getList(){
      let that = this
      this.$http.get('api/wxfwgl/resultgetlist?isyc=1&page=' + that.currentPage + '&region_id=' + that.region_id)
      .then(function(res) {
        console.log(res)
        that.moveList = res.data.data.data
        if(that.currentPage == 1){
          that.$message.success('获取列表成功！')
          that.last_page = res.data.data.last_page
        }
      })
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        that.regionList = res.data.data
        console.log(res.data.data)
        if(res.data.code != 1){
          that.$$message.warning('获取地区列表异常，请重试！')
        }
      })
    }
  },
  created() {
    this.getList()
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.getRegion()
  },
}
</script>
<style scoped>
  .listWrap{
    margin-top: 50px;
  }
  .addBtn{
    position: absolute;
    right: 30px;
  }
  .table{
    height: 200px;
  }
  .el-pagination{
    margin-top: 30px;
    position: absolute;
    left: 50%;
    transform: translateY(-50%);
  }
  .switchArea{
    position: absolute;
    margin-top: -5px;
    right: 20px;
  }
</style>