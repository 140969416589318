<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>公司列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="switchArea" v-if="userInfo.permission==1">
      <el-select v-model="region_id" placeholder="筛选地区" @change="getList()">
        <el-option
          v-for="item in regionList"
          :key="item.region_id"
          :label="item.region_name"
          :value="item.region_id">
        </el-option>
      </el-select>
    </div>
    <div class="listWrap">
      <el-table
        :data="firmList.filter(data => !search || data.company_name.toLowerCase().includes(search.toLowerCase()))"
        style="width:100%;">
        <el-table-column label="公司名称" prop="company_name" width="240px"></el-table-column>
        <el-table-column label="审核状态" prop="is_write"
        column-key="is_write"
        :filter-method="filterHandler"
        :filters="[{text: '未审核', value: 0},{text: '已审核', value: 1}]">
          <template slot-scope="scope0">
            <el-tag
              :type="writeObj[scope0.row.is_write].type"
              disable-transitions>{{writeObj[scope0.row.is_write].detail}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="有组织废气" prop="write_zzfq"
        column-key="write_zzfq"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope1">
            <el-tag
              v-if="scope1.row.write_zzfq!=0"
              :type="typeObj[scope1.row.write_zzfq].type"
              disable-transitions>{{typeObj[scope1.row.write_zzfq].detail}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="无组织废气" prop="write_wzzfq"
        column-key="write_wzzfq"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope2">
            <el-tag
              v-if="scope2.row.write_wzzfq!=0"            
              :type="typeObj[scope2.row.write_wzzfq].type"
              disable-transitions>{{typeObj[scope2.row.write_wzzfq].detail}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column label="废水监测" prop="write_fsjc"
        column-key="write_fsjc"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope3">
            <el-tag
              v-if="scope3.row.write_fsjc!=0"
              :type="typeObj[scope3.row.write_fsjc].type"
              disable-transitions>{{typeObj[scope3.row.write_fsjc].detail}}</el-tag>
          </template>          
        </el-table-column>
        <el-table-column label="噪声监测" prop="write_zsjc"
        column-key="write_zsjc"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope4">
            <el-tag
              v-if="scope4.row.write_zsjc!=0"
              :type="typeObj[scope4.row.write_zsjc].type"
              disable-transitions>{{typeObj[scope4.row.write_zsjc].detail}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="周边环境监测" prop="write_zbhjjc"
        column-key="write_zbhjjc"
        :filter-method="filterHandler"
        :filters="[{text: '合格', value: 1},{text: '不合格', value: 2}]">
          <template slot-scope="scope5">
            <el-tag
              v-if="scope5.row.write_zbhjjc!=0"
              :type="typeObj[scope5.row.write_zbhjjc].type"
              disable-transitions>{{typeObj[scope5.row.write_zbhjjc].detail}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="right">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="mini"
              placeholder="输入关键字搜索"/>
          </template>
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="handleHistory(scope.$index, scope.row)">历史</el-button>
            <el-button
              size="small"
              type="primary"
              @click="handleCheck(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        @current-change="handleCurrentChange"
        :total="last_page * 10">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      writeObj: [
        {type: 'warning',detail: '未审核'},
        {type: 'success',detail: '已审核'}
      ],
      typeObj: [
        {},
        {type: 'primary',detail: '合格'},
        {type: 'danger',detail: '不合格'}
      ],
      firmList: [],
      currentPage: 1,
      last_page: 1,
      search: '',
      region_id: '',
      regionList: [],
      userInfo: ''
    }
  },
  methods: { 
    handleHistory(index, row) {
      window.sessionStorage.setItem('firmInfo_exam', JSON.stringify(this.firmList[index]))
      this.$router.push({path: '/exam/history'})
    },
    handleCheck(index, row) {
      window.sessionStorage.setItem('firmInfo_exam', JSON.stringify(this.firmList[index]))
      this.$router.push({path: '/exam/check'})
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    handleCurrentChange(val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    getList(){
      let that = this
      this.$http.get('api/monitor/zxjcresultgetlist?page=' + that.currentPage + '&region_id=' + that.region_id)
      .then(function(res) {
        console.log(res.data.data.data)
        that.firmList = res.data.data.data
        if(that.currentPage == 1){
          that.$message.success('获取列表成功！')
          that.last_page = res.data.data.last_page
        }
      })
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        that.regionList = res.data.data
        console.log(res.data.data)
        if(res.data.code != 1){
          that.$$message.warning('获取地区列表异常，请重试！')
        }
      })
    }
  },
  created() {
    this.getList()
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.getRegion()
  },
}
</script>
<style scoped>
  .listWrap{
    margin-top: 50px;
  }
  .addBtn{
    position: absolute;
    right: 30px;
  }
  .table{
    height: 200px;
  }
  .el-pagination{
    margin-top: 30px;
    position: absolute;
    left: 50%;
    transform: translateY(-50%);
  }
  .switchArea{
    position: absolute;
    margin-top: -5px;
    right: 20px;
  }
</style>