<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>我的账户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="infoWrap">
      <el-descriptions class="margin-top" :column="1" border>
        <template slot="extra" v-if="userInfo.permission!=1">
          <el-button @click="navAlter" type="primary" size="small">修改</el-button>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-user"></i> 账号</div>
          </template>
          <div class="infoBox">{{userInfo.user_name}}</div>
        </el-descriptions-item>
        <el-descriptions-item v-if="userInfo.permission!=1">
          <template slot="label">
            <div class="labelBox"><i class="el-icon-location-outline"></i> 地区</div>
          </template>
          <div class="infoBox">{{region}}</div>
        </el-descriptions-item>
        <!-- <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-key"></i> 密码</div>
          </template>
          <div class="infoBox">12345</div>
        </el-descriptions-item> -->
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-collection-tag"></i> 姓名</div>             
          </template>
          <div class="infoBox">{{userInfo.real_name}}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-male"></i> 性别</div>            
          </template>
          <div class="infoBox">{{sexObj[userInfo.sex]}}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-bank-card"></i> 身份证</div>            
          </template>
          <div class="infoBox">{{userInfo.idcard_no}}</div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <div class="labelBox"><i class="el-icon-coordinate"></i> 权限</div>            
          </template>
          <div class="infoBox">{{rightObj[userInfo.permission-1]}}</div>
        </el-descriptions-item>
      </el-descriptions>
    <el-button type="danger" @click="logout" class="logout_btn">退出</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: '',
      sexObj: ['女','男'],
      region: '',
      rightObj: ['管理员','地区管理人','员工']
    }
  },
  methods: {
    navAlter(){
      this.$router.push('/mine/alter')
    },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        console.log(res.data.data)
        for(let i = 0;i<res.data.data.length;i++){
          if(res.data.data[i].region_id == that.userInfo.region_id){
            that.region = res.data.data[i].region_name
          }
        }
      })
    },
  },
  mounted() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.getRegion()
  },
}
</script>

<style scoped>
  .infoWrap{
    margin-top: 20px;
  }
  .labelBox{
    min-width: 120px;
  }
  .descImg{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .infoBox{
    min-width: 1000px;
  }
  .logout_btn{
    position: absolute;
    bottom: 100px;
  }
</style>