<template>
  <!-- <div class="welWrap">
    <div class="topTitle">欢迎登录！</div>
    <div class="midTitle">科信环保管家信息管理平台</div>
    <div class="btmTitle">环保管家，科信致善</div>
  </div> -->
  <div class="imgBox">
    <img src="../../assets/img/welcome.png" alt="" width="100%" height="100%">
  </div>

</template>

<script>
export default {

}
</script>

<style>
  .imgBox{
    width: 100%;
    box-sizing: border-box;
  }
  .welWrap{
    margin-top: 80px;
  }
  .topTitle{
    font-size: 100px;
    font-weight: 800;
    letter-spacing: 5px;
    background-image: linear-gradient(150deg,#173690,#70E685);
    background-clip:text;
    -webkit-background-clip:text;
    color: transparent;
  }
  .midTitle{
    font-size: 80px;
    font-weight: 800;
    letter-spacing: 2px;
    background-image: linear-gradient(240deg,#173690,#2B5E8C);
    background-clip:text;
    -webkit-background-clip:text;
    color: transparent;
  }
  .btmTitle{
    font-size: 60px;
    font-weight: 800;
    letter-spacing: 2px;
    margin-top: 10px;
    color: #4EA28A;
  }
</style>