import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/analsis/Welcome.vue'
import Chart from '../components/analsis/Chart.vue'
import ChartFirm from '../components/analsis/FileList.vue'
import ChartExam from '../components/analsis/ExamList.vue'
import ChartMove from '../components/analsis/MoveList.vue'
import ChartExcept from '../components/analsis/ExceptList.vue'
import ChartPollution from '../components/analsis/PollutionList.vue'
import UserList from '../components/user/file/List.vue'
import UserInfo from '../components/user/file/Info.vue'
import UserAdd from '../components/user/file/Add.vue'
import UserAlter from '../components/user/file/Alter.vue'
import MineInfo from '../components/user/mine/Info.vue'
import MineAlter from '../components/user/mine/Alter.vue'
import FirmList from '../components/firm/file/List.vue'
import FirmInfo from '../components/firm/file/Info.vue'
import FirmAdd from '../components/firm/file/Add.vue'
import FirmAlter from '../components/firm/file/Alter.vue'
import ExamList from '../components/firm/exam/List.vue'
import ExamCheck from '../components/firm/exam/Check.vue'
import ExamHistory from '../components/firm/exam/History.vue'
import MoveList from '../components/firm/move/List.vue'
import MoveInfo from '../components/firm/move/Info.vue'
import MoveAdd from '../components/firm/move/Add.vue'
import MoveAlter from '../components/firm/move/Alter.vue'
import MoveCheck from '../components/firm/move/Check.vue'
import PollutionList from '../components/firm/pollution/List.vue'
import PollutionCheck from '../components/firm/pollution/Check.vue'
import ExceptionList from '../components/firm/exception/List.vue'

Vue.use(VueRouter)


const router = new VueRouter({
  routes: [
    { path: '/login', component: Login },
    { 
      path: '/home', 
      component: Home,
      children: [
        { path: '/welcome', component: Welcome},
        { path: '/analsis/chart', component: Chart},
        { path: '/analsis/firm', component: ChartFirm},
        { path: '/analsis/exam', component: ChartExam},
        { path: '/analsis/move', component: ChartMove},
        { path: '/analsis/except', component: ChartExcept},
        { path: '/analsis/pollution', component: ChartPollution},
        { path: '/user/list', component: UserList},
        { path: '/user/info', component: UserInfo},
        { path: '/user/add', component: UserAdd},
        { path: '/user/alter', component: UserAlter},
        { path: '/mine/info', component: MineInfo},
        { path: '/mine/alter', component: MineAlter},
        { path: '/firm/list', component: FirmList},
        { path: '/firm/info', component: FirmInfo},
        { path: '/firm/add', component: FirmAdd},
        { path: '/firm/alter', component: FirmAlter},
        { path: '/exam/list', component: ExamList},
        { path: '/exam/check', component: ExamCheck},
        { path: '/exam/history', component: ExamHistory},
        { path: '/move/list', component: MoveList},
        { path: '/move/info', component: MoveInfo},
        { path: '/move/add', component: MoveAdd},
        { path: '/move/alter', component: MoveAlter},
        { path: '/move/check', component: MoveCheck},
        { path: '/pollution/list', component: PollutionList},
        { path: '/pollution/check', component: PollutionCheck},
        { path: '/exception/list', component: ExceptionList},
      ]
    },
    { path: '/', redirect: '/login' }
  ]
})

export default router
