<template>
<el-container>
  <el-header height="60px">
    <div class="header_left">
      <img src="../assets/img/band_logo.png" alt="">
      <span>科信环保管家</span>
    </div>
    <div class="header_right">
      <template v-if="region">
        <i class="el-icon-location"></i>
        <span> {{region}}</span>
      </template>
      <el-button type="primary" @click="navAccount" class="check_btn">查看账号</el-button>
    </div>
  </el-header>
  <el-container>
      <!-- 侧边栏 -->
      <el-aside width="250px">
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#fff"
          text-color="#6E7079"
          active-text-color="#173690"
          :collapse-transition="false"
          :router="true"
        >
          <!-- 一级菜单 -->
          <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{ item.listName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="'/' + sunItem.path" v-for="sunItem in item.children" :key="sunItem.id">
              <template slot="title">
                <!-- 二级菜单的模板区域 -->
                <i :class="iconsObj[sunItem.id]"></i>
                <!-- 图标 -->
                <span>{{ sunItem.listName }}</span>
                <!-- 文本 -->
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menuList: [
        {
          id: 1,
          listName: '数据概览',
          children: [
            { id: 11, listName: '数据图示', path: 'analsis/chart' }
          ]
        },
        {
          id: 2,
          listName: '账号管理',
          children: [
            { id: 21, listName: '管理员列表', path: 'user/list' }
          ]
        },
        {
          id: 3,
          listName: '企业管理',
          children: [
            { id: 31, listName: '企业列表', path: 'firm/list' }
          ]
        },
        {
          id: 4,
          listName: '污染源排放',
          children: [
            { id: 41, listName: '污染源排放监控管理', path: 'exam/list' }
          ]
        },
        {
          id: 5,
          listName: '危废管理',
          children: [
            { id: 51, listName: '危废转运和监督', path: 'move/list' }
          ]
        },
        {
          id: 6,
          listName: '防治异常',
          children: [
            { id: 61, listName: '生产及污染防治措施异常', path: 'pollution/list' }
          ]
        },
        {
          id: 7,
          listName: '企业异常',
          children: [
            { id: 71, listName: '企业异常情况管理', path: 'exception/list' }
          ]
        }
      ],
      iconsObj: {
        1: 'el-icon-data-analysis',
        11: 'el-icon-pie-chart',
        2: 'el-icon-user',
        21: 'el-icon-edit',
        3: 'el-icon-office-building',
        31: 'el-icon-suitcase',
        32: 'el-icon-coordinate',
        4: 'el-icon-coin',
        41: 'el-icon-view',
        5: 'el-icon-receiving',
        51: 'el-icon-document',
        6: 'el-icon-warning-outline',
        61: 'el-icon-tickets',
        7: 'el-icon-files',
        71: 'el-icon-notebook-2',
      },
      activePath: '',
      region: ''
    }
  },
  methods: {
    navAccount(){
      this.$router.push('/mine/info')
    }
  },
  created() {
    let that = this
    let userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.$http.get('api/region/getAll')
    .then(function(res) {
      // console.log(res)
      for(let i = 0;i<res.data.data.length;i++){
        if(userInfo.region_id == res.data.data[i].region_id){
          that.region = res.data.data[i].region_name
        }
      }
    })
  },
}
</script>

<style scoped>
  .el-header{
    text-align: center;
    box-shadow: 0 0 16px darkgray;
    display: flex;
    align-items: center;
    padding: 0;
    z-index: 2;
    width: 100%;
  }
  
  .el-aside {
    padding-top: 12px;
    height: 100%;
    box-shadow: 8px 0 8px -8px darkgray;
    z-index: 1;
  }
  
  .el-main {
    text-align: center;
    height: 100%;
    background: #F3F4FA;
  }
  .el-container{
    min-height: 100%;
  }
  .header_left img{
    height: 45px;
    margin-left: 25px;
  }
  .header_left span{
    position: absolute;
    line-height: 45px;
    margin-left: 15px;
    font-weight: 600;
    background-image: linear-gradient(150deg,#173690,#2B5E8C);
    background-clip:text;
    -webkit-background-clip:text;
    color: transparent;
  }
  .header_right{
    position: absolute;
    right: 30px;
  }
  .check_btn{
    padding: 0 12px;
    height: 30px;
    font-size: 12px;
    border: none;
    background: #173690;
    color: #FDFDFC;
    font-weight: 600;
  }
  .header_right span{
    margin-right: 30px;
    font-size: 15px;
    font-weight: 600;
  }
  .el-button:hover{
    background: #2D5363;
  }
  .el-submenu span{
    font-weight: 600;
    font-size: 15px;
  }
</style>