<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/user/list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user/info' }">用户信息</el-breadcrumb-item>
      <el-breadcrumb-item>修改信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="infoWrap">
      <el-input placeholder="请输入旧密码" type="password" v-model="oldPwd" clearable class="infoWrap_input" show-password></el-input>
      <el-input placeholder="请输入新密码" type="password" v-model="password" clearable class="infoWrap_input" show-password></el-input>
    </div> -->
    <div class="infoWrap">
      <div style="float:left;margin-bottom:10px;" v-if="mineInfo.permission == 1">
        <el-select v-model="region_id" placeholder="请选择新的地区">
          <el-option
            v-for="item in regionList"
            :key="item.region_id"
            :label="item.region_name"
            :value="item.region_id">
          </el-option>
        </el-select>
      </div>
      <el-input placeholder="请输入新的姓名"  v-model="real_name" clearable class="infoWrap_input"></el-input>
      <el-input placeholder="请输入新的身份证"  v-model="idcard_no" clearable class="infoWrap_input"></el-input>
      <div class="radioBox">
        <template>
        <el-radio-group v-model="sex">
          <el-radio-button :label="0">男</el-radio-button>
          <el-radio-button :label="1">女</el-radio-button>
        </el-radio-group>
        </template>
      </div>
    </div>
    <el-button type="primary" @click="submit" class="submit_btn">提交修改</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 密码
      oldPwd: '',
      password: '',
      // 基本信息
      real_name: '',
      idcard_no: '',
      sex: 1,
      userInfo: '',
      mineInfo: '',
      // 地区
      regionList: [],
      region_id: ''
    }
  },
  methods: {
    submit(){
      let {
        real_name,
        idcard_no,
        region_id,
        sex,
      } = this      
      let that = this
      if(real_name!=''&&idcard_no!=''){
        that.$http.get('api/user/update',{
          params: {
            id: that.userInfo.user_id,
            real_name: real_name,
            sex: sex,
            idcard_no: idcard_no,
            region_id: region_id
          }
        })
        .then(function(res) {
          console.log(res)
          if(res.data.code == 1){
            that.$message.success('修改成功！')
            that.$router.push('/user/list')
          }
        })
      }
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        that.regionList = res.data.data
        console.log(res.data.data)
        if(res.data.code == 1){
          that.$message.success('获取地区列表成功！')
          for(let i = 0;i<res.data.data.length;i++){
            if(res.data.data[i].region_id == that.userInfo.region_id){
              that.region_id = res.data.data[i].region_id
            }
          }
        }
      })
    },
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_file'))
    this.mineInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    this.real_name = this.userInfo.real_name
    this.idcard_no = this.userInfo.idcard_no
    this.sex = this.userInfo.sex
    this.getRegion()
  },
}
</script>

<style scoped>
  .infoWrap{
    padding: 30px 20% 0;
    box-sizing: border-box;
  }
  .infoWrap_input{
    margin: 10px 0;
  }
  .inputBox{
    width: 80px;
  }
  .submit_btn{
    width: 200px;
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
  .radioBox{
    margin-top: 10px;
    float: left;
  }
  .radioBox_text{
    margin: 0 25px 0 24px;
    font-size: 15px;
    color: #6E7079;
  }
</style>