<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/firm/list' }">公司列表</el-breadcrumb-item>
      <el-breadcrumb-item>创建公司</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="infoWrap">
      <!-- 公司基本信息 -->
      <el-form class="formBox" ref="infoForm" :model="infoForm"  align="left">
        <el-form-item>
          <div class="uploadTitle">公司基本信息 <el-switch v-model="formSwicth.form0"></el-switch></div>
        </el-form-item>
        <div v-if="formSwicth.form0">
          <el-form-item label="公司名称" prop="company_name" label-width="80px">
            <el-input v-model="infoForm.company_name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="信用编号" prop="credit_no" label-width="80px">
            <el-input v-model="infoForm.credit_no" placeholder="请输入编号"></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="address" label-width="80px">
            <el-input v-model="infoForm.address" placeholder="请输入地址"></el-input>
          </el-form-item>
          <el-form-item label="法人名字" prop="legal_name" label-width="80px">
            <el-input v-model="infoForm.legal_name" placeholder="请输入名字"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="tel" label-width="80px">
            <el-input v-model="infoForm.tel" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="公司介绍" prop="introduce" label-width="80px">
            <el-input v-model="infoForm.introduce" placeholder="请输入介绍"></el-input>
          </el-form-item>
          <el-form-item label="营业执照" prop="introduce" label-width="80px">
            <el-upload
              name="license"
              ref="upload1"
              class="upload-demo"
              :action="'https://jingquekejifuwu.powerv.top/api/company/updateLicense?id='+infoForm.company_id"
              :auto-upload="false"
              :on-success="handleSuccess"
              :on-error="handleError"
              :limit="1"
              accept=".jpg,.png,.pdf"
              :before-upload="beforeUpload"
              >
              <el-button type="primary" size="small" class="uploadBtn" @click="hasFile.form1=1">选择图片</el-button>
              <div slot="tip" class="el-upload__tip">点击上传图片</div>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <!-- 环境影响评价办理情况 -->
      <el-form :model="situationForm1" align="left">
        <el-form-item>
          <div class="uploadTitle">环境影响评价办理情况 <el-switch v-model="formSwicth.form1"></el-switch></div>
        </el-form-item>
        <div v-if="formSwicth.form1">
          <el-form-item label="办理情况" label-width="80px">
            <el-radio-group v-model="situationForm1.type">
              <el-radio :label="1">已办理</el-radio>
              <el-radio :label="2">未办理</el-radio>
              <el-radio :label="3">无需办理</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="situationForm1.type==1" label="上传文件" label-width="80px">
            <el-switch v-model="hasFile.form1"></el-switch>
          </el-form-item>
          <div v-show="hasFile.form1">
            <el-form-item label="选择分类" label-width="80px">
              <el-radio-group v-model="situationForm1.sort" style="float:left;">
                <el-radio-button :label="1">报告书</el-radio-button>
                <el-radio-button :label="2">报告表</el-radio-button>
                <el-radio-button :label="3">登记管理</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="批复文号" label-width="80px">
              <el-input v-model="situationForm1.code" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="取得时间" label-width="80px">
              <el-date-picker value-format="yyyy-M-d" type="date" placeholder="选择日期" v-model="situationForm1.date" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-upload
              name="file[]"
              ref="upload2"
              class="upload-demo"
              :action="'https://jingquekejifuwu.powerv.top/api/hpzzsh/hjpjAdd?is_update_file=1&company_id='+infoForm.company_id+'&type='+situationForm1.type+'&sort='+situationForm1.sort+'&approval_no='+situationForm1.code+'&get_date='+situationForm1.date"
              :auto-upload="false"
              :on-success="handleSuccess"
              :on-error="handleError"
              accept=".jpg,.png,.docx,.doc,.pdf"
              :before-upload="beforeUpload"
              >
              <el-button type="primary" size="small" class="uploadBtn">选择文件</el-button>
              <div slot="tip" class="el-upload__tip">点击上传(图片、pdf、word文档)</div>
            </el-upload>
          </div>
        </div>
      </el-form>
      <!-- 排污许可办理情况 -->
      <el-form :model="situationForm2" align="left">
        <el-form-item>
          <div class="uploadTitle">排污许可办理情况 <el-switch v-model="formSwicth.form2"></el-switch></div>
        </el-form-item>
        <div v-if="formSwicth.form2">
          <el-form-item label="办理情况" label-width="80px">
            <el-radio-group v-model="situationForm2.type">
              <el-radio :label="1">已办理</el-radio>
              <el-radio :label="2">未办理</el-radio>
              <el-radio :label="3">无需办理</el-radio>
            </el-radio-group>
          </el-form-item>
            <el-form-item label="上传文件" label-width="80px" v-if="situationForm2.type==1">
              <el-switch v-model="hasFile.form2"></el-switch>
            </el-form-item>
            <div v-if="hasFile.form2">
              <el-form-item label="选择分类" label-width="80px">
                <el-radio-group v-model="situationForm2.sort" style="float:left;">
                  <el-radio-button :label="1">重点管理</el-radio-button>
                  <el-radio-button :label="2">简化管理</el-radio-button>
                  <el-radio-button :label="3">登记管理</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="办理编号" label-width="80px">
                <el-input v-model="situationForm2.code" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="取得时间" label-width="80px">
                <el-date-picker type="date" value-format="yyyy-M-d" placeholder="选择日期" v-model="situationForm2.date" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-upload
                name="file"
                ref="upload3"
                class="upload-demo"
                :action="'https://jingquekejifuwu.powerv.top/api/hpzzsh/pwxkAdd?is_update_file=1&company_id='+infoForm.company_id+'&type='+situationForm1.type+'&sort='+situationForm2.sort+'&approval_no='+situationForm2.code+'&get_date='+situationForm2.date"
                :auto-upload="false"
                :on-success="handleSuccess"
                :on-error="handleError"
                :limit="1"
                accept=".jpg,.png,.docx,.doc,.pdf"
                :before-upload="beforeUpload"
                >
                <el-button type="primary" size="small" class="uploadBtn">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">点击上传(图片、pdf、word文档)</div>
              </el-upload>
            </div>
        </div>
      </el-form>
      <!-- 环境风险应急预案编制情 -->
      <el-form align="left">
        <el-form-item>
          <div class="uploadTitle">环境风险应急预案编制情况 <el-switch v-model="formSwicth.form3"></el-switch></div>
        </el-form-item>
        <el-form-item label="办理情况" label-width="80px" v-show="formSwicth.form3">
          <el-radio-group v-model="situationForm3.type">
            <el-radio :label="1">已办理</el-radio>
            <el-radio :label="2">未办理</el-radio>
            <el-radio :label="3">无需办理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传文件" label-width="80px" v-if="formSwicth.form3&&situationForm3.type==1">
          <el-switch v-model="hasFile.form3"></el-switch>
        </el-form-item> 
        <el-upload
          v-if="hasFile.form3"
          name="file[]"
          ref="upload4"
          class="upload-demo"
          :action="'https://jingquekejifuwu.powerv.top/api/hpzzsh/hjyaAdd?company_id='+infoForm.company_id + '&type=' + situationForm3.type"
          :auto-upload="false"
          :on-success="handleSuccess"
          :on-error="handleError"
          :limit="1"
          accept=".jpg,.png,.pdf"
          :before-upload="beforeUpload"
        >
          <el-button type="primary" size="small" class="uploadBtn">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">点击上传备案表照片及险应急文档(.pdf文件或图片)</div>
        </el-upload>
      </el-form>
      <!-- 项目环保验收办理情况 -->
      <el-form align="left">
        <el-form-item>
          <div class="uploadTitle">项目环保验收办理情况 <el-switch v-model="formSwicth.form4"></el-switch></div>
        </el-form-item>
        <el-form-item label="办理情况" label-width="80px" v-show="formSwicth.form4">
          <el-radio-group v-model="situationForm4.type">
            <el-radio :label="1">已办理</el-radio>
            <el-radio :label="2">未办理</el-radio>
            <el-radio :label="3">无需办理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传文件" label-width="80px" v-if="formSwicth.form4&&situationForm4.type==1">
          <el-switch v-model="hasFile.form4"></el-switch>
        </el-form-item> 
        <el-upload
          v-if="hasFile.form4"
          name="file"
          ref="upload5"
          class="upload-demo"
          :action="'https://jingquekejifuwu.powerv.top/api/hpzzsh/hbysAdd?company_id='+infoForm.company_id + '&type=' + situationForm4.type"
          :auto-upload="false"
          :on-success="handleSuccess"
          :on-error="handleError"
          :limit="1"
          accept=".jpg,.png,.pdf"
          :before-upload="beforeUpload"
        >
          <el-button type="primary" size="small" class="uploadBtn">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">点击上传验收报告(.word,.png,.jpg,.pdf文件)</div>
        </el-upload>
      </el-form>
      <!-- 在线监测-标题 -->
      <el-form align="left">
        <el-form-item>
          <div class="uploadTitle">在线监测情况 <el-switch v-model="formSwicth.form6"></el-switch></div>
        </el-form-item>
      </el-form>
      <!-- 在线监测-表单 -->
      <el-form align="left" v-for="item in onlineForm" :key="item.id">
        <div v-if="formSwicth.form6">
          <el-form-item>
            <template>
              <el-form-item :label="onlineOptions[item.id]">
                <el-radio-group v-model="onlineForm[item.id].type">
                  <el-radio :label="1">已建设</el-radio>
                  <el-radio :label="2">未建设</el-radio>
                  <el-radio :label="0">无需建设</el-radio>
                </el-radio-group>
              </el-form-item>
            </template>
          </el-form-item>
          <div v-if="item.type == 1">
            <el-form-item label="联网链接" label-width="80px">
              <el-input v-model="item.link" placeholder="请输入联网链接"></el-input>
            </el-form-item>
            <el-form-item label="单位名称" label-width="80px">
              <el-input v-model="item.name" placeholder="请输入单位名称"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <!-- 危险废物管理 -->
      <el-form align="left" style="margin-top:24px;">
        <div class="uploadTitle">危险废物管理 <el-switch v-model="formSwicth.form7"></el-switch></div>
        <div v-if="formSwicth.form7">
          <el-form-item>
            <el-radio-group v-model="trashForm.type">
              <el-radio :label="1">已实施管理</el-radio>
              <el-radio :label="2">未实施管理</el-radio>
              <el-radio :label="0">无需管理</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="trashForm.type==1">
            <!-- <div class="subTitle">危废暂存间</div>
            <el-form-item>
              <el-checkbox-group v-model="trashForm.room.option" align="left">
                <el-checkbox :disabled="item.id==0?null:trashForm.room.disable"
                  @change="item.id==0?(trashForm.room.disable?trashForm.room.disable=false:(trashForm.room.disable=true,trashForm.room.option=[0],trashForm.room.other='')):null"
                  v-for="item in roomOptions"
                  :label="item.id"
                  :key="item.id">{{item.detail}}</el-checkbox>
              </el-checkbox-group>
              <el-input :disabled="trashForm.room.disable" v-model="trashForm.room.other" placeholder="请输入内容">
                <template slot="prepend">其他</template>
              </el-input>
            </el-form-item> -->
            <div class="subTitle">
              处置协议
              <el-button size="small" type="danger" style="float:right;" @click="cutDeal()">处置协议 -</el-button>
              <el-button size="small" type="primary" style="float:right;margin-right:5px;" @click="addDeal()">+ 处置协议</el-button>
            </div>
            <el-form-item v-for="(item, i) in trashForm.deal" :key="i">
              <el-button size="mini" type="danger" style="float:right;margin:-10px 0 5px;" @click="cutSort(i)" plain>危废编号 -</el-button>
              <el-button size="mini" type="primary" style="float:right;margin:-10px 5px 5px 0;" @click="addSort(i)" plain>+ 危废编号</el-button>
              <el-input v-model="item.name" placeholder="请输入内容">
                <template slot="prepend">单位名称*{{i+1}}</template>
              </el-input>
              <el-input v-model="item.license" placeholder="请输入内容">
                <template slot="prepend">危废经营许可证</template>
              </el-input>
              <el-input v-for="subItem in item.sort" :key="subItem.id" v-model="subItem.val" placeholder="请输入内容">
                <template slot="prepend">可处理危废种类编号[{{subItem.id}}]</template>
              </el-input>
            </el-form-item>
            <!-- <div class="subTitle">危险废物管理</div>
            <el-form-item>
              <el-checkbox-group v-model="trashForm.manage.option" align="left">
                <el-checkbox :disabled="item.id==0?null:trashForm.manage.disable"
                  @change="item.id==0?(trashForm.manage.disable?trashForm.manage.disable=false:(trashForm.manage.disable=true,trashForm.manage.option=[0],trashForm.manage.other='')):null"
                  v-for="item in manageOptions"
                  :label="item.id"
                  :key="item.id">{{item.detail}}</el-checkbox>
              </el-checkbox-group>
              <el-input :disabled="trashForm.manage.disable" v-model="trashForm.manage.other" placeholder="请输入内容">
                <template slot="prepend">其他</template>
              </el-input>
            </el-form-item> -->
          </div>
        </div>
      </el-form>
    <el-button :loading="uploadLoading" type="primary" @click="submit()" class="submit_btn">确认无误，点击创建</el-button>      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 公司基本信息
      infoForm: {
        company_id: 1,
        company_name: '',
        legal_name: '',
        credit_no: '',
        introduce: '',
        address: '',
        tel: ''
      },
      hasFile: {
        form1: false,
        form2: false,
        form3: false,
        form4: false,
        form5: false,
      },
      // 表单开启状态
      formSwicth: {
        form0: false,
        form1: false,
        form2: false,
        form3: false,
        form4: false,
        form5: false,
        form6: false,
        form7: false
      },
      situationForm1:{type: 1,sort: '',code: '',date: '',fileList: []},
      situationForm2:{type: 1,sort: '',code: '',date: '',fileList: []},
      situationForm3:{type: 1},
      situationForm4:{type: 1},
      checkForm1:{check1: [],check2: []},
      bindCheck1: [false,false,false,false,false],
      bindCheck2: [false,false,false,false,false],
      checkOptions: [
        {id: 0,detail: '有组织废气'},
        {id: 1,detail: '无组织废气'},
        {id: 2,detail: '废水监测'},
        {id: 3,detail: '噪声监测'},
        {id: 4,detail: '周边环境监测'}
      ],
      // 在线监测表单
      onlineForm: [
        {id: 0,type: 0,link: '',name: ''},
        {id: 1,type: 0,link: '',name: ''},
        {id: 2,type: 0,link: '',name: ''},
      ],
      // 危险废物表单
      trashForm: {
        type: 1,
        room: {
          disable: false,
          option: [],
          other: ''
        },
        deal: [
          {
            name: '',
            license: '',
            sort: [{id:0,val:''}]
          }
        ],
        manage: {
          disable: false,
          option: [],
          other: ''
        }
      },
      roomOptions: [
        {id: 0,detail: '基本满足危险废物管理要求'},
        {id: 1,detail: '未设置危险废物标识牌'},
        {id: 2,detail: '危废贮存间未设置导流沟和收集池'},
        {id: 3,detail: '危废贮存间未建设防腐防渗'},
        {id: 4,detail: '未分类设置危险废物暂存区'}
      ],
      manageOptions: [
        {id: 0,detail: '建设基本满足设计规范要求'},
        {id: 1,detail: '未建设危险废物管理台账'},
        {id: 2,detail: '危险废物管理制度未上墙'},
        {id: 3,detail: '未张贴危险废防治责任信息牌'}
      ],
      // 在线监测选项
      onlineOptions: ['有组织废气','无组织废气','废水监测'],
      uploadLoading: false,
      filename: '',
    }
  },
  methods: {
    submit(){
      let that = this
      let {
        company_name,
        credit_no,
        address,
        legal_name,
        tel,
        introduce
      } = this.infoForm
      let {
        form0,
        form1,
        form2,
        form3,
        form4,
        form5,
        form6,
        form7,
      } = this.formSwicth
      if(form0){
        let isOk = true
        if(form7){
          let {room,deal,manage} = that.trashForm
          var status = true
          // if(that.trashForm.type == 1){
          //   // if(room.option.length==0||manage.option.length==0){status=false,isOk=false}
          //   for(let i =0;i<deal.length;i++){
          //     if(deal[i].name==''||deal[i].license==''){status=false,isOk=false}
          //     for(let j=0;j<deal[i].sort.length;j++){
          //       if(deal[i].sort[j].val==''){status=false,isOk=false}
          //     }
          //   }
          // }
        }
        if(isOk){
          if(company_name!=''&&credit_no!=''){
            this.$http.post('api/company/create?is_update_file=0&company_name='+company_name+'&credit_no='+credit_no+'&address='+address+'&legal_name='+legal_name+'&tel='+tel+'&introduce='+introduce+'&region_id=1')
            .then(function(res) {
              console.log(res)
              if(res.data.code == 1){
                var company_id = res.data.data.id
                that.infoForm.company_id = company_id
                that.$message.success('创建成功！')
                setTimeout(() =>{
                  that.$refs.upload1.submit()
                  if(form1){
                    let type = that.situationForm1.type
                    if(that.hasFile.form1){
                      that.$refs.upload2.submit()
                    } else {
                      that.$http.get('api/hpzzsh/hjpjAdd',{
                        params: {
                          company_id: company_id,
                          type: type,
                          is_update_file: 0
                        }
                      })
                      .then(function(res) {
                        console.log(res)
                        if(res.data.code == 1){
                          that.$message.success('提交成功！')
                        }
                      })                      
                    }
                  }
                  if(form2){
                    let type = that.situationForm2.type
                    if(that.hasFile.form2){
                      that.$refs.upload3.submit()
                    } else {
                      that.$http.get('api/hpzzsh/pwxkAdd',{
                        params: {
                          company_id: company_id,
                          type: type,
                          is_update_file: 0
                        }
                      })
                      .then(function(res) {
                        if(res.data.code == 1){
                          that.$message.success('提交成功！')
                        }
                        console.log(res)
                      })                      
                    }
                  }
                  if(form3){
                    let type = that.situationForm3.type
                    if(that.hasFile.form3){
                      that.$refs.upload4.submit()
                    } else {
                      that.$http.get('api/hpzzsh/hjyaAdd',{
                        params: {
                          is_update_file: 0,
                          company_id: company_id,
                          type: that.situationForm3.type
                        }
                      })
                      .then(function(res) {
                        console.log(res)
                        if(res.data.code == 1){
                          that.$message.success('提交成功！')
                        }              
                      })
                    }
                  }
                  if(form4){
                    let type = that.situationForm4.type
                    if(that.hasFile.form4){
                      that.$refs.upload5.submit()
                    } else {
                      that.$http.get('api/hpzzsh/hbysAdd',{
                        params: {
                          is_update_file: 0,
                          company_id: company_id,
                          type: that.situationForm4.type
                        }
                      })
                      .then(function(res) {
                        console.log(res)
                        if(res.data.code == 1){
                          that.$message.success('提交成功！')
                        }
                      })                      
                    }
                  }
                  if(form5){
                    let checkObj = {zzfq:0,wzzfq:0,fsjc:0,zsjc:0,zbhjjc:0}
                    for(let i=0;i<5;i++){
                      if(that.bindCheck1[i]&&i==0){
                        checkObj.zzfq = 1
                      } else if(that.bindCheck1[i]&&i==1){
                        checkObj.wzzfq = 1
                      } else if(that.bindCheck1[i]&&i==2){
                        checkObj.fsjc = 1
                      } else if(that.bindCheck1[i]&&i==3){
                        checkObj.zsjc = 1
                      } else if(that.bindCheck1[i]&&i==4){
                        checkObj.zbhjjc = 1
                      } else if(that.bindCheck2[i]&&i==0){
                        checkObj.zzfq = 2
                      } else if(that.bindCheck2[i]&&i==1){
                        checkObj.wzzfq = 2
                      } else if(that.bindCheck2[i]&&i==2){
                        checkObj.fsjc = 2
                      } else if(that.bindCheck2[i]&&i==3){
                        checkObj.zsjc = 2
                      } else if(that.bindCheck2[i]&&i==4){
                        checkObj.zbhjjc = 2
                      }
                    }
                    console.log(checkObj)
                    that.$http.get('api/monitor/zxjcUpdate',{
                      params: {
                        company_id: company_id,
                        zzfq: checkObj.zzfq,
                        wzzfq: checkObj.wzzfq,
                        fsjc: checkObj.fsjc,
                        zsjc: checkObj.zsjc,
                        zbhjjc: checkObj.zbhjjc,
                      }
                    })
                    .then(function(res) {
                      console.log(res)
                      if(res.data.code == 1){
                        // that.$message.success('修改成功！')
                      } else {
                        // that.$message.error('修改失败，请重试！')
                      }
                    })
                  }
                  if(form6){
                    let onlineForm = that.onlineForm
                    let yzzfq = onlineForm[0].type
                    let yzzfq_other = JSON.stringify([{link:onlineForm[0].link,name:onlineForm[0].name}])
                    let wzzfq = onlineForm[1].type
                    let wzzfq_other = JSON.stringify([{link:onlineForm[1].link,name:onlineForm[1].name}])
                    let fsgl = onlineForm[2].type
                    let fsgl_other = JSON.stringify([{link:onlineForm[2].link,name:onlineForm[2].name}])
                    that.$http.get('api/monitor/onlinejcUpdate',{
                      params: {
                        company_id: company_id,
                        yzzfq: yzzfq,
                        yzzfq_other: yzzfq_other,
                        wzzfq: wzzfq,
                        wzzfq_other: wzzfq_other,
                        fsgl: fsgl,
                        fsgl_other: fsgl_other
                      }
                    })
                    .then(function(res) {
                      console.log(res)
                      if(res.data.code == 1){
                        // that.$message.success('修改成功！')
                      } else {
                        // that.$message.error('修改失败，请重试！')
                      }
                    })            
                  }
                  if(form7){
                    let {room,deal,manage} = that.trashForm
                    if(room.disable){
                      var wfzcj_is = 1
                    } else {
                      var wfzcj_is = 2
                    }
                    if(manage.disable){
                      var jlfa_is = 1
                    } else {
                      var jlfa_is = 2
                    }
                    if(status){
                      that.$http.get('api/wxfwgl/add',{
                      params: {
                        company_id: company_id,
                        type: that.trashForm.type,
                        qsxy_is: 1,
                        qsxy_content: JSON.stringify(deal),
                      }
                    })
                    .then(function(res) {
                      if(res.data.code == 1){
                        // that.$message.success('修改成功！')
                      }
                    })
                    } else {
                      that.$message.warning('危险废物管理填写不完整！')
                    }
                  }
                },1000);
                // that.$router.push({path: '/firm/list'})
              } else if(res.data.code == 0){
                that.$message.error('请重新登录！')
                that.$router.push('/login')
              } else if(res.data.code == 2){
                that.$message.warning('公司信用编号冲突，请重新输入！')
                that.credit_no = ''
              }
            })
          } else {
            that.$message.warning('请完整填写信息')
          }
        } else {
          that.$message.warning('请完整填写信息')
        }
      } else {
          that.$message.warning('请先填写公司基本信息！')
          that.formSwicth.form0 = true
      }
    },
    upload() {
      this.$refs.upload.submit()
    },
    beforeUpload(file) {
      let that = this
      let isLt2M = true
      isLt2M = file.size / 1024 / 1024 < 100
      if (!isLt2M) {
        that.loading = false
        that.$message.error('上传文件大小不能超过 100MB!')
      }
      that.filename = file.name
      return isLt2M
    },
    addDeal(){
      let newObj = {
        name: '',
        license: '',
        sort: [{id:0,val:''}]
      }
      this.trashForm.deal.push(newObj)
    },
    cutDeal(){
      if(this.trashForm.deal.length > 1){
        this.trashForm.deal.pop()
      } else {
        this.$message.warning('至少填写一项')
      }
    },
    addSort(i){
      let newObj = {
        id: this.trashForm.deal[i].sort.length,
        val: ''
      }
      this.trashForm.deal[i].sort.push(newObj)
    },
    cutSort(i){
      if(this.trashForm.deal[i].sort.length > 1){
        this.trashForm.deal[i].sort.pop()
      } else {
        this.$message.warning('至少填写一项')
      }
    },
    handleSuccess(response, file, fileList) {
      console.log('文件上传成功！')
      // this.$message.success('文件上传成功！')
    },
    // 监听上传失败
    handleError(e, file, fileList) {
      let that = this
      const msg = JSON.parse(e.message)
      that.$message.error(msg)
      that.loading = false
    }
  },
}
</script>

<style scoped>
  .infoWrap{
    padding: 20px 16% 0;
    box-sizing: border-box;
  }
  .uploadTitle{
    color: #606266;
    font-size: 16px;
    font-weight: 800;
  }
  .subTitle{
    margin-top: 20px;
    color: #606266;
    font-size: 18px;
    width: 100%;
    height: 50px;
  }
  .upload-demo{
    border: 1px solid #C0C4CC;
    border-radius: 8px;
    padding: 10px;
    min-height: 200px;
  }
  .uploadBtn{
    float: left;
  }
  .submit_btn{
    width: 200px;
    margin-top: 40px;
  }
  .el-form-item{
    margin-bottom: 15px;
  }
</style>