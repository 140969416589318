<template>
  <div>
    <div class="switchArea" v-if="regionShow">
      <el-select v-model="region_id" placeholder="请选择地区" @change="getData()">
        <el-option
          v-for="item in regionList"
          :key="item.region_id"
          :label="item.region_name"
          :value="item.region_id">
        </el-option>
      </el-select>
    </div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Chart' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>数据图示</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="countWrap">
      <div class="countBox user" @click="navUser()">
        <div class="countTitle">
          管理员统计
        </div>
        <div class="countDetail">
          {{chartData.count.admin}} 人
        </div>
      </div>
      <div class="countBox firm" @click="navFirm()">
        <div class="countTitle">
          公司统计
        </div>
        <div class="countDetail">
          {{chartData.count.company}} 家
        </div>
      </div>
    </div>
    <div id="main">
      <div class="chart">
        <div id="pie1" class="chartBox"></div>
        <div id="pie2" class="chartBox"></div>
      </div>
      <div class="chart">
        <div id="col1" class="chartBox" style="height:270px;"></div>
        <div id="col2" class="chartBox" style="height:270px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      regionList: [],
      region_id: '',
      chartData: {count:{admin: '',company: ''}},
      regionShow: true
    }
  },
  methods: {
    drawLine () {
      let that = this
      var echarts = require('echarts');
      var myChart1 = echarts.init(document.getElementById('pie1'));
      var myChart2 = echarts.init(document.getElementById('pie2'));
      var myChart3 = echarts.init(document.getElementById('col1'));
      var myChart4 = echarts.init(document.getElementById('col2'));
      myChart1.on('click',function(params){
        let pathList =  ['/analsis/firm','/analsis/exam','/analsis/move','/analsis/pollution']
        that.$router.push({path: pathList[params.dataIndex]})
      });
      myChart3.on('click',function(params){
        let pathList =  ['/analsis/firm','/analsis/exam','/analsis/move','/analsis/pollution']
        that.$router.push({path: pathList[params.dataIndex]})
      });
      myChart2.on('click',function(params){
        that.$router.push({path: '/exception/list'})
      });
      myChart4.on('click',function(params){
        that.$router.push({path: '/exception/list'})
      });
      var option1,option2,option3,option4;
      option1 = {
        title: {
          text: '问题类型',
          subtext: '不同类型公司占比',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom',
          left: 'center'
        },
        series: [
          {
            name: '问题类型',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              normal: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
                //每根柱子颜色设置
                color: function(params) {
                    let colorList = [
                      "#FFCC99",
                      "#FF9999",
                      "#FF9900",
                      "#FF6666"
                    ];
                  return colorList[params.dataIndex];
                }
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: that.chartData.ychz.yc_hgx, name: '合规性管理异常' },
              { value: that.chartData.ychz.yc_zxjc, name: '自行监测管理异常' },
              { value: that.chartData.ychz.yc_wf, name: '危废管理异常' },
              { value: that.chartData.ychz.yc_rcyx, name: '日常运行管理异常' }
            ]
          }
        ]
      };
      option2 = {
        title: {
          text: '问题公司',
          subtext: '正常与问题公司比例',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom',
          left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              normal: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
                //每根柱子颜色设置
                color: function(params) {
                    let colorList = [
                      "#FF6600",
                      "#99CC66"
                    ];
                  return colorList[params.dataIndex];
                }
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: that.chartData.right.n, name: '问题企业' },
              { value: that.chartData.right.y, name: '合格企业' }
            ]
          }
        ]
      };
      option3 = {
        //标题配置信息
        title: {
            text: "问题类型",
            left: "center"
        },
        xAxis: {
            type: "category",
            data: [
                "合规性管...",
                "自行监测...",
                "危废管理...",
                "日常运行..."
            ],
            itemStyle: {
                narmal: {
                    color: "orange"
                }
            }
        },
        yAxis: {
            type: "value"
        },
        series: [
            {
                data: [
                    that.chartData.ychz.yc_hgx,
                    that.chartData.ychz.yc_zxjc,
                    that.chartData.ychz.yc_wf,
                    that.chartData.ychz.yc_rcyx
                ],
                type: "bar",
                barWidth: "40%",
                itemStyle: {
                    normal: {
                        //每根柱子颜色设置
                        color: function(params) {
                            let colorList = [
                              "#FFCC99",
                              "#FF9999",
                              "#FF9900",
                              "#FF6666"
                            ];
                            return colorList[params.dataIndex];
                        }
                    }
                },
                //柱状图上显示数据
                label: {
                    show: "true",
                    position: "top",
                    color: "#333",
                    fontWeight: "bolder",
                    fontSize: "20"
                },
            }
        ]
      };
      option4 = {
        //标题配置信息
        title: {
            text: "问题公司",
            left: "center"
        },
        xAxis: {
            type: "category",
            data: [
                "问题企业",
                "合格企业"
            ],
            itemStyle: {
                narmal: {
                    color: "orange"
                }
            }
        },
        yAxis: {
            type: "value"
        },
        series: [
            {
                data: [
                    that.chartData.right.n,
                    that.chartData.right.y
                ],
                type: "bar",
                barWidth: "20%",
                itemStyle: {
                    normal: {
                        //每根柱子颜色设置
                        color: function(params) {
                            let colorList = [
                              "#FF6600",
                              "#99CC66"
                            ];
                            return colorList[params.dataIndex];
                        }
                    }
                },
                //柱状图上显示数据
                label: {
                    show: "true",
                    position: "top",
                    color: "#333",
                    fontWeight: "bolder",
                    fontSize: "20"
                },
            }
        ]
      };
      option1 && myChart1.setOption(option1);
      option2 && myChart2.setOption(option2);
      option3 && myChart3.setOption(option3);
      option4 && myChart4.setOption(option4);
    },
    getRegion(){
      let that = this
      this.$http.get('api/region/getAll')
      .then(function(res) {
        that.regionList = res.data.data
        console.log(res.data.data)
        if(res.data.code == 1){
          that.$message.success('获取地区列表成功！')
          that.region_id = that.regionList[0].region_id
        }
      })
    },
    getData(){
      let that = this
      this.$http.get('api/query/sum?region_id=' + that.region_id)
      .then(function(res) {
        that.chartData = res.data.data
        console.log(res.data.data)
        if(res.data.code == 1){
          that.$message.success('获取统计数据成功！')
          that.drawLine()
        }
      })      
    },
    navUser(){
      this.$router.push('/user/list')
    },
    navFirm(){
      this.$router.push('/firm/list')
    },
  },
  mounted() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo_mine'))
    if(this.userInfo.permission == 1){
      this.getRegion();
    } else {
      this.region_id = this.userInfo.region_id
      this.regionShow = false
    }
    this.getData();
  },
}
</script>

<style scoped>
#main{
  width: 100%;
  height: 600px;
  padding-top: 20px;
}
.switchArea{
  position: absolute;
  right: 30px;
}
.countWrap{
  height: 150px;
  width: 100%;
  display: inline-flex;
  box-sizing: border-box;
  padding: 10px 150px;
}
.countBox{
  cursor: pointer;
  width: 20%;
  margin: 0 15%;
  height: 120px;
  border-radius: 5px;
}
.user{
  background: #FFCC99;
}
.firm{
  background: #A5DC6E;
}
.chartBox{
  width: 100%;
  box-sizing: border-box;
  height: 330px;
}
.countTitle{
  position: absolute;
  font-weight: 800;
  font-size: 16px;
  margin: 8px 0 0 12px;
  color: rgba(0,0,0,0.8);
}
.countDetail{
  line-height: 120px;
}
.chart{
  padding: 0 150px;
  width: 100%;
  display: inline-flex;
  box-sizing: border-box;
}
</style>